import React, { useState } from "react";
import {
  IconArrowsSort,
  IconBrandCashapp,
  IconGraphFilled,
  IconHomeFilled,
  IconMenu2,
  IconSpeakerphone,
  IconWallet,
  IconX,
} from "@tabler/icons-react";
import { useLocation, useNavigate } from "react-router-dom";
import p2p from "../../Assets/icons/p2p.svg";
import history from "../../Assets/icons/History.svg";
import { getLocale } from "../../constants/language";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../redux/user/actions";
// import { PopUp } from "../pages/Landing";
import { ROUTES } from "../../constants/routes";
import {
  IconAppWindowFilled,
  
  IconTransformFilled,
} from "@tabler/icons-react";
import deposit from "../../Assets/icons/deposit.svg";
import cart from "../../Assets/icons/Cart.svg";

const Navbar = ({ toggleDrawer, isP2P }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [liteOpen, setLiteOpen] = useState(false);
  const [isLite, setisLite] = useState(false);
  const { liteRoute, P2PLiteorderPayload, user } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  const handleNavigate = () => {
    if (user?.p2p?.mode==="LITE") {
      dispatch(actions.openCloseLiteRoute(true));
      setisLite(true)
    } else {
      navigate("/market");
    }
  };

  return (
    <div>
      {isP2P ? (
        <div className=" bg-white">
          {/* {mode === "Advertisement" && ( */}
          <div
            className="h-[80px] max-w-[425px] bg-white shadow-lg fixed bottom-0 left-0 right-0 mx-auto text-center pt-2"
            style={{ boxShadow: "0 -4px 10px rgba(0, 0, 0, 0.3)" }}
          >
            <div className="flex justify-around h-full items-center ">
              <div className=" flex flex-col items-center">
                <div
                  className={` ${
                    pathname === "/P2PHOME" ? "opacity-100" : "opacity-60"
                  } size-10 cursor-pointer hover:bg-lightGrey rounded-full p-2 flex justify-center items-center flex-col`}
                >
                  <img
                    className=" w-full h-full"
                    src={p2p}
                    onClick={() => navigate("/P2PHOME")}
                  />
                </div>
                <p className=" text-[10px] leading-normal tracking-wide">
                  All Ads
                </p>
              </div>
              <div className=" flex flex-col items-center">
                <div
                  className={` ${
                    pathname === "/P2POrder" ? "opacity-100" : "opacity-60"
                  } size-10 cursor-pointer hover:bg-lightGrey rounded-full p-2 flex justify-center items-center flex-col`}
                >
                  <img
                    className=" w-full h-full"
                    src={history}
                    onClick={() => navigate("/P2POrder")}
                  />
                </div>
                <p className=" text-[10px] leading-normal tracking-wide">
                  Pendings
                </p>
              </div>
              <div className=" flex flex-col items-center">
                <div>
                  <IconHomeFilled
                    onClick={() => navigate("/home")}
                    className="size-11 text-white hover:text-gray-600 cursor-pointer hover:bg-lightGrey rounded-full p-2 bg-bgBlue"
                  />
                </div>
                <p className=" text-[10px] leading-normal tracking-wide">
                  Home
                </p>
              </div>
              <div className=" flex flex-col items-center">
                <div>
                  <IconSpeakerphone
                    onClick={() => navigate("/myAdds")}
                    className={` ${
                      pathname === "/myAdds" ? "opacity-100" : "opacity-60"
                    } size-10 cursor-pointer hover:bg-lightGrey rounded-full p-2`}
                  />
                </div>
                <p className=" text-[10px] leading-normal tracking-wide">
                  My Ads
                </p>
              </div>
              <div className=" flex flex-col items-center">
                <div>
                  <IconBrandCashapp
                    onClick={() => navigate("/paymentMethods")}
                    className={` ${
                      pathname === "/paymentMethods"
                        ? "opacity-100"
                        : "opacity-60"
                    } size-10 cursor-pointer hover:bg-lightGrey rounded-full p-2`}
                  />
                </div>
                <p className=" text-[10px] leading-normal tracking-wide">Pay</p>
              </div>
            </div>
          </div>
          {/* )} */}
        </div>
      ) : 
        <div
          className="h-[80px] max-w-[425px] bg-white shadow-lg fixed bottom-0 left-0 right-0 mx-auto text-center pt-2"
          style={{ boxShadow: "0 -4px 10px rgba(0, 0, 0, 0.3)" }}
        >
          <div className="flex justify-around h-full items-center ">
            <div className=" flex flex-col items-center">
              <div>
                <IconWallet
                  data-tour="dashboard"
                  onClick={() => navigate("/wallet")}
                  className={` ${
                    pathname === "/wallet" ? "opacity-100" : "opacity-60"
                  } size-10 cursor-pointer hover:bg-lightGrey rounded-full p-2`}
                />
              </div>
              <p className=" text-[10px] leading-normal tracking-wide">
                {/* Wallets */}
                {getLocale("wallets")}
              </p>
            </div>
            <div className=" flex flex-col items-center">
              <div>
                <IconHomeFilled
                  data-tour="connect-profile"
                  onClick={() => navigate("/home")}
                  className={` ${
                    pathname === "/home" ? "opacity-100" : "opacity-60"
                  } size-10 cursor-pointer hover:bg-lightGrey rounded-full p-2`}
                />
              </div>
              <p className=" text-[10px] leading-normal tracking-wide">Home</p>
            </div>
            <div className=" flex flex-col items-center">
              <div>
                <IconArrowsSort
                  data-tour="publish-post"
                  onClick={() => handleNavigate()}
                  className="size-11 text-white hover:text-gray-600 cursor-pointer hover:bg-lightGrey rounded-full p-2 rotate-90 bg-bgBlue"
                />
              </div>
              <p className=" text-[10px] leading-normal tracking-wide">
                {/* Market */}
                {getLocale("market")}
              </p>
            </div>
            <div className="flex flex-col items-center ">
              <div>
                <IconGraphFilled
                  // onClick={() => navigate("/P2PHome")}
                  data-tour="schedule-post"
                  className="opacity-60 size-10 cursor-pointer hover:bg-lightGrey rounded-full p-2 "
                />
              </div>
              <p className=" text-[10px] leading-normal tracking-wide">
                {/* Analytics */}
                {getLocale("transaction")}
              </p>
            </div>
            <div className=" flex flex-col items-center">
              <div>
                <IconMenu2
                  onClick={toggleDrawer}
                  data-tour="accounts-summary"
                  className="opacity-60 cursor-pointer size-10 hover:bg-lightGrey rounded-full p-2 "
                />
              </div>
              <p className=" text-[10px] leading-normal tracking-wide">
                {/* Menu */}
                {getLocale("menu")}
              </p>
            </div>
          </div>
        </div>
      // ) : (
      }
        <PopUp open={isLite} setisLite={setisLite}>
          <div
            style={{
              margin: "auto",
              maxWidth: 425,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            className=" space-y-4 bg-bgWhite rounded-tl-xl py-4  rounded-tr-xl"
          >
            <div
              onClick={() => {
                navigate(ROUTES.LITEBUY);
                dispatch(
                  actions.setP2PLitePaylaod({
                    ...P2PLiteorderPayload,
                    type: "BUY",
                  })
                );
                dispatch(actions.openCloseLiteRoute(false));
              }}
              className=" px-4 flex cursor-pointer  text-[#111111] hover:bg-[#ccc] py-2  font-semibold"
            >
              {" "}
              {/* Buy  */}
              <div className=" flex items-center justify-start gap-2">
                <img src={cart} alt="" className=" size-5 " />{" "}
                <div>
                  <p className="text-[14px] text-left">{getLocale("buy")}</p>
                  <p className=" text-[11px] font-normal text-left  opacity-80 ">
                  {getLocale("buyLite")}
                  </p>
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                navigate(ROUTES.LITEBUY);
                dispatch(
                  actions.setP2PLitePaylaod({
                    ...P2PLiteorderPayload,
                    type: "SELL",
                  })
                );
                dispatch(actions.openCloseLiteRoute(false));
              }}
              className=" px-4 flex cursor-pointer  text-[#111111] hover:bg-[#ccc] py-2  font-semibold"
            >
              {" "}
              {/* Buy  */}
              <div className=" flex items-center justify-start gap-2">
                <img src={deposit} alt="" />{" "}
                <div>
                  <p className="text-[14px] text-left">{getLocale("sell")}</p>
                  <p className=" text-[11px] font-normal text-left  opacity-80 ">
                  {getLocale("sellLite")}
                  </p>
                </div>
              </div>
            </div>
            <div
              // onClick={() => {
              //   navigate(ROUTES.CONVERT);
              //   dispatch(actions.openCloseLiteRoute(false));
              // }}
              className=" px-4 flex  text-[#111111] hover:bg-[#ccc] bg-[#ccc] cursor-not-allowed py-2  font-semibold"
            >
              {" "}
              {/* Buy  */}
              <div className=" flex items-center justify-start gap-2">
                <IconTransformFilled size={20} />
                <div>
                  <p className="text-[14px] text-left">
                    {getLocale("Convert")}
                  </p>
                  <p className=" text-[11px] font-normal text-left  opacity-80 ">
                  {getLocale("convertLite")}
                  </p>
                </div>
              </div>
            </div>
            <div
              // onClick={() => {
              //   navigate(ROUTES.DEPOSIT);
              //   dispatch(actions.openCloseLiteRoute(false));
              // }}
              className=" px-4 flex  text-[#111111] hover:bg-[#ccc] bg-[#ccc] cursor-not-allowed py-2  font-semibold"
            >
              {" "}
              <div className=" flex items-center justify-start gap-2">
                <IconAppWindowFilled size={20} />
                <div>
                  <p className="text-[14px] text-left">
                    {getLocale("deposit")}
                  </p>
                  <p className=" text-[11px] font-normal text-left  opacity-80 ">
                    {getLocale("tradeLite")}
                  </p>
                </div>
              </div>
            </div>
            <div className=" flex  justify-center items-center px-2.5 pt-2.5 ">
              <div className=" cursor-pointer px-2 py-2 rounded-full bg-bgBlue text-textWhite shadow-2xl active:scale-[1.02] transition-all active:bg-bgWhite  border border-black active:text-textPrime ">
                <IconX
                  onClick={() =>setisLite(false)}
                  size={25}
                  className=" cursor-pointer text-inherit "
                />
              </div>
            </div>
          </div>
        </PopUp>
      {/* <PopUp height={30} setOpen={setLiteOpen} open={liteOpen}>
        <div></div>
      </PopUp> */}
    </div>
  );
};

export default Navbar;

export function PopUp({ children, open,setisLite }) {
  const dispatch = useDispatch();
  return (
    <div
      style={{
        position: "fixed",
        // maxWidth: 425,
        // left:'min(50%,425px)'
        // left: '50%',
        // transform: `translateX(-50 %)`
      }}
      onClick={(e) => {
        setisLite(false);
      }}
      className={` z-50 ${
        open ? "h-[100%]" : "h-0"
      } transition-all  flex flex-col justify-end border-t bottom-0 overflow-hidden absolute w-full bg-bgBlue/[0.5] left-0 shadow-2xl `}
    >
      <div>{children}</div>
    </div>
  );
}
