import { IconArrowNarrowLeft } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getNotification, getUserProfileUpdate } from "../../service/api";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../redux/user/actions";
import { getLocale } from "../../constants/language";
import moment from "moment/moment";

function Notifications() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [userData, setUserData] = useState(user);
  const [data, setData] = useState([]);

  const handleProfile = (data, check) => {
    let body = {
      [data]: check,
    };
    getUserProfileUpdate(body)
      ?.then((res) => {
        if (res?.status) {
          setUserData({ ...userData, [data]: check });
          let newUser = { ...user, [data]: check };
          dispatch(actions.setUser(newUser));
        }
      })
      .catch((e) => {
        console.log("%c Line:15 🍉 e", "color:#2eafb0", e);
      });
  };

  const getNotificationData = () => {
    getNotification()
      ?.then((res) => {
        if (res?.status) {
          setData(res?.data?.result);
        }
      })
      ?.catch((e) => {
        console.log("%c Line:39 🥥 e", "color:#f5ce50", e);
      });
  };

  useEffect(() => {
    getNotificationData();
  }, []);

  return (
    <div>
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col">
        <div className=" flex justify-between items-center">
          <IconArrowNarrowLeft className="size-7 cursor-pointer" />
          <h2 className=" font-semibold leading-[140%] tracking-[-1px] ">
            {/* Security Alert Settings */}
            {getLocale("secAlert")}
          </h2>
          <p></p>
        </div>

        <div className=" mt-5 hidden">
          <div className=" px-4  py-4 rounded-md w-full border border-[#CFCFCF] text-left">
            <p className=" text-[14px] leading-normal tracking-[140%] text-[#707070] font-normal">
              {/* Security notification */}
              {getLocale("secNotification")}
            </p>
            <h2 className="text-[16px] mt-1 tracking-[140%] text-[#111111] font-normal">
              {/* Your password has been changed */}
              {getLocale("passChange")}
            </h2>
            <p className="text-[16px] leading-[140%] text-[#707070] font-normal">
              January 1st 2024
            </p>
          </div>
          <p className="text-[16px] leading-[140%] text-[#707070] font-normal text-left mt-3">
            {/* Receive notifications about important security alerts, such as
            password resets. */}
            {getLocale("receiveNotification")}
          </p>
        </div>

        <div className="mt-[15px]">
          <div>
            <h1 className="text-[22px] tracking-[-1px] leading-[145%] text-[#111111] font-medium text-left ">
              {/* Preferences */}
              {getLocale("preferences")}
            </h1>
            <p className="text-[16px] leading-[140%] tracking-[0.2px] text-[#707070] font-normal text-left ">
              {/* How would you like to be notified */}
              {getLocale("preferencesInfo")}
            </p>
          </div>
          <label className="flex items-center justify-between cursor-pointer mt-5">
            <span className="text-sm font-medium text-gray-900 dark:text-gray-300 checked:text-[#707070]">
              {/* Push */}
              {getLocale("push")}
            </span>
            <input
              type="checkbox"
              checked={user?.pushNotification}
              onChange={(e) =>
                handleProfile("pushNotification", e.target.checked)
              }
              className="sr-only peer"
            />
            <div className="relative w-12 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[0px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
          </label>
          <label className="flex items-center justify-between cursor-pointer mt-7">
            <span className="text-sm font-medium text-gray-900 dark:text-gray-300">
              {/* Email */}
              {getLocale("email")}
            </span>
            <input
              type="checkbox"
              checked={user?.emailNotification}
              onChange={(e) =>
                handleProfile("emailNotification", e.target.checked)
              }
              className="sr-only peer"
            />
            <div className="relative w-12 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[0px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
          </label>
          <label className="flex items-center justify-between cursor-pointer mt-7">
            <span className="text-sm font-medium text-gray-900 dark:text-gray-300">
              {/* SMS(required) */}
              {getLocale("SMS")}
            </span>
            <input
              type="checkbox"
              checked={user?.smsNotification}
              onChange={(e) =>
                handleProfile("smsNotification", e.target.checked)
              }
              className="sr-only peer"
            />
            <div className="relative w-12 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[0px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
          </label>
          <label className="flex items-center justify-between cursor-pointer mt-7">
            <span className="text-sm font-medium text-gray-900 dark:text-gray-300">
              {/* In the App */}
              {getLocale("inTheApp")}
            </span>
            <input
              type="checkbox"
              checked={user?.inAppNotification}
              onChange={(e) =>
                handleProfile("inAppNotification", e.target.checked)
              }
              className="sr-only peer"
            />
            <div className="relative w-12 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[0px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
          </label>
        </div>

        <div className=" mt-12">
          <p className="text-[16px] leading-[140%] text-[#707070] font-normal text-left my-3">
            {/* Receive notifications about important security alerts, such as
            password resets. */}
            {getLocale("receiveNotification")}
          </p>
          {data && data?.length > 0 ? (
            data?.map((obj, index) => {
              return (
                <div
                  key={index}
                  className="px-4 py-4 my-1 rounded-md w-full border border-[#CFCFCF] text-left"
                >
                  <p className="text-[14px] leading-normal tracking-[140%] text-[#707070] font-normal">
                    {obj?.title}
                  </p>
                  <h2 className="text-[16px] mt-1 tracking-[140%] text-[#111111] font-normal">
                    {obj?.body}
                  </h2>
                  <p className="text-[16px] leading-[140%] text-[#707070] font-normal">
                    {moment(obj?.createdAt).format("DD-MM-YYYY h:mm A")}
                  </p>
                </div>
              );
            })
          ) : (
            <div className="px-4 py-4 rounded-md w-full border border-[#CFCFCF] text-left">
              <p className="text-[14px] leading-normal tracking-[140%] text-[#707070] font-normal">
                No any notifications
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Notifications;
