import {
  IconArrowsExchange2,
  IconCheck,
  IconChevronLeft,
  IconCopy,
  IconStarFilled,
  IconStarHalfFilled,
} from "@tabler/icons-react";
import { IconStar, IconStarHalf, IconStarOff } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Confrim, Danger, Success } from "../../Core/Errors";
import {
  confirmP2POrder,
  getAllP2pOrders,
  getAllplacedP2POrder,
  getAllplacedP2POrderForUser,
  rateOrder,
  verifyP2POrder,
} from "../../../service/api";
import Loader from "../../Core/Loader";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import PopUp from "./PopUp";
import { ROUTES } from "../../../constants/routes";
import { getLocale } from "../../../constants/language";

const P2POrders = () => {
  const [isActive, setIsActive] = useState(true);
  const [isActive2, setIsActive2] = useState(true);
  const [getAll, setGetAll] = useState(false);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isRateNow, setRateNow] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const { user } = useSelector((state) => state.user);
  
  const getAllP2POrders = () => {
    try {
      const params = isActive
        ? {
            page: pageNumber,
            pagination: true,
            sizePerPage: sizePerPage,
            // paymentStatus: "COMPLETED",
            // orderStatus: "PENDING",
          }
        : {
            page: pageNumber,
            pagination: true,
            sizePerPage: sizePerPage,
            orderStatus: "COMPLETED",
            paymentStatus: "COMPLETED",
          };
      setLoader(true);
      getAllplacedP2POrder(params)
        .then((res) => {
          console.log("res: ", res);
          setData(res?.data?.result);
          setLoader(false);
        })
        .catch((error) => {
          Danger(error?.response?.data?.message);
          setLoader(false);
        });
    } catch (error) {
      Danger(error?.response?.data?.message);
      setLoader(false);
    }
  };
  const getAllP2POrdersThisUser = () => {
    try {
      const params = isActive2
        ? {
            page: pageNumber,
            pagination: true,
            sizePerPage: sizePerPage,
            orderStatus: "PENDING",
          }
        : {
            page: pageNumber,
            pagination: true,
            sizePerPage: sizePerPage,
            orderStatus: "COMPLETED",
            paymentStatus: "COMPLETED",
          };
      setLoader(true);
      getAllplacedP2POrderForUser(params, user?._id)
        .then((res) => {
          console.log("res: ", res);
          setData2(res?.data?.result);
          setLoader(false);
        })
        .catch((error) => {
          Danger(error?.response?.data?.message);
          setLoader(false);
        });
    } catch (error) {
      Danger(error?.response?.data?.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    getAllP2POrders();
  }, [isActive]);

  useEffect(() => {
    if (getAll) getAllP2POrdersThisUser();
  }, [isActive2]);

  const confirmOrder = (id) => {
    Confrim("Are you sure you want to verify this payment").then(
      async (value) => {
        switch (value) {
          case "cancel":
            break;
          case "catch":
            try {
              setLoader(true);
              try {
                confirmP2POrder(id)
                  .then((res) => {
                    Success(res?.message).then((_) => {
                      window.location.reload();
                    });
                    setLoader(false);
                  })
                  .catch((error) => {
                    Danger(error?.response?.data?.message);
                    setLoader(false);
                  });
              } catch (error) {
                Danger(error?.response?.data?.message);
                setLoader(false);
              }
            } catch (error) {}
            break;

          default:
            break;
        }
      }
    );
  };

  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [payload, setPayload] = useState({});
  const handleSingleClick = (value) => {
    setRating(value + 0.5);
  };

  const handleDoubleClick = (value) => {
    setRating(value);
  };

  const handleStarClick = (index) => {
    if (index < rating) {
      setRating(index); // Clear stars after the clicked one
    }
  };

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      const fullStarValue = i;
      const halfStarValue = i - 0.5;

      if (rating >= fullStarValue) {
        stars.push(
          <IconStarFilled
            key={i}
            className="text-yellow-500 cursor-pointer"
            onClick={() => handleStarClick(i - 1)}
            onDoubleClick={() => handleDoubleClick(fullStarValue)}
            size={32}
          />
        );
      } else if (rating >= halfStarValue) {
        stars.push(
          <IconStarHalfFilled
            key={i}
            className="text-yellow-500 cursor-pointer"
            onClick={() => handleStarClick(i - 1)}
            onDoubleClick={() => handleDoubleClick(fullStarValue)}
            size={32}
          />
        );
      } else {
        stars.push(
          <IconStar
            key={i}
            className="text-gray-400 cursor-pointer"
            onClick={() => handleSingleClick(halfStarValue)}
            // onDoubleClick={() => handleDoubleClick(fullStarValue)}
            size={32}
          />
        );
      }
    }
    return stars;
  };
  console.log("payload", payload);
  const handleRating = () => {
    const formdata = {
      orderId: payload?.offerId?._id,
      orderTrxId: payload?._id,
      buyerId: payload?.buyerId?._id,
      sellerId: payload?.offerId?.sellerId,
      ratedByUserId: payload?.sellerFlag
        ? payload?.offerId?.sellerId
        : payload?.buyerId?._id,
      ratedForUserId: payload?.sellerFlag
        ? payload?.buyerId?._id
        : payload?.offerId?.sellerId,
      message: feedback,
      starRating: rating,
    };
    setLoader(true);
    console.log("formdata: ", formdata);
    rateOrder(formdata)
      .then((res) => {
        Success(res?.message).then((_) => {
          window.location.reload();
        });
        setLoader(false);
      })
      .catch((error) => {
        Danger(error?.response?.data?.message);
        setLoader(false);
      });
    try {
    } catch (error) {}
  };
  return (
    <div>
      <div>
        <div className="  relative overflow-x-hidden">
          {loader && <Loader />}
          <div className="h-full min-h-[100vh] font-sansflex flex-col bg-bgWhite relative">
            <div className=" flex justify-between items-center pt-12 w-full px-4 py-4 bg-bgBlue">
              <IconChevronLeft
                color="white"
                className="size-7 cursor-pointer"
                onClick={() => navigate(-1)}
              />
              <h1 className=" flex justify-center items-center text-textWhite font-bold tracking-[1.02px] leading-[140%] gap-1">
                {/* P2P Orders */}
                P2P{getLocale("orders")}
                <IconArrowsExchange2 color="white" />
              </h1>
              <div>
                {/* <select
                  value={selectedCurrency}
                  onChange={(e) => setSelectedCurrency(e.target.value)}
                  className="outline-none border-[1px] border-borderWhite rounded-sm"
                >
                  {currList?.map((curr, index) => (
                    <option value={curr}>{curr}</option>
                  ))}
                </select> */}
              </div>
            </div>

            <div className=" h-full w-full bg-bgWhite rounded-tl-xl rounded-tr-xl py-4 z-10 ">
              <div className=" flex justify-between items-center px-4 ">
                <div className="flex justify-between text-[16px] leading-[140%] px-0.5 py-0.5 items-center border h-[35px] min-w-[160px] rounded-md overflow-hidden ">
                  <button
                    onClick={() => setIsActive(true)}
                    className={` transition-all ${
                      isActive ? "bg-bgBlue text-white" : ""
                    } rounded-md w-1/2 h-full`}
                  >
                    {/* Ongoing */}
                    {getLocale("ongoing")}
                  </button>
                  <button
                    onClick={() => setIsActive(false)}
                    className={` transition-all px-2 py-1 ${
                      isActive ? "" : "bg-bgBlue text-white"
                    } rounded-md w-1/2 h-full`}
                  >
                    {/* Fulfilled */}
                    {getLocale("fullfilled")}
                  </button>
                </div>

                <div>
                  <button
                    onClick={() => {
                      setGetAll(true);
                      getAllP2POrdersThisUser();
                    }}
                    className={` transition-all px-4 py-1.5 bg-bgBlue text-white
                     rounded-md   h-full`}
                  >
                    {/* Get Own */}
                    {getLocale("getOwn")}
                  </button>
                </div>
              </div>

              <div className=" relative bg-bgWhite">
                <div
                  className={` bg-bgWhite max-h-[75vh] overflow-y-auto w-full left-0 top-0 absolute transition-all py-2 ${
                    isActive ? "translate-x-0 " : " translate-x-[100%]"
                  } no-scrollbar`}
                >
                  {data && data?.length > 0 ? (
                    data?.filter((filt) => filt?.orderStatus !== "COMPLETED")
                      ?.length > 0 ? (
                      data
                        ?.filter((filt) => filt?.orderStatus !== "COMPLETED")
                        ?.map((item, index) => (
                          <P2PCard
                            setRateNow={setRateNow}
                            setPayload={setPayload}
                            confirmOrder={(id) => confirmOrder(id)}
                            data={item}
                            buy={true}
                            key={`buy-${index}`}
                            item={item}
                          />
                        ))
                    ) : (
                      <div className="bg-transparent border border-gray-600 rounded-lg p-4 w-[90%] mx-auto text-black font-medium text-lg mt-6">
                        {/* No any sell order found */}
                        {getLocale("noSell")}{" "}
                      </div>
                    )
                  ) : (
                    <div className="bg-transparent border border-gray-600 rounded-lg p-4 w-[90%] mx-auto text-black font-medium text-lg mt-6">
                      {/* No any sell order found */}
                      {getLocale("noSell")}{" "}
                    </div>
                  )}
                </div>
                <div
                  className={`bg-bgWhite max-h-[75vh] transition-all left-0 top-0 w-full absolute overflow-y-auto py-2 ${
                    isActive ? "-translate-x-[100%]" : " translate-x-0"
                  } no-scrollbar`}
                >
                  {data && data?.length > 0 ? (
                    data?.filter((filt) => filt?.orderStatus === "COMPLETED")
                      ?.length > 0 ? (
                      data
                        ?.filter((filt) => filt?.orderStatus === "COMPLETED")
                        ?.map((item, index) => (
                          <P2PCard
                            setPayload={setPayload}
                            setRateNow={setRateNow}
                            confirmOrder={(id) => confirmOrder(id)}
                            data={item}
                            buy={true}
                            key={`buy-${index}`}
                            item={item}
                          />
                        ))
                    ) : (
                      <div className="bg-transparent border border-gray-600 rounded-lg p-4 w-[90%] mx-auto text-black font-medium text-lg mt-6">
                        {/* No any sell order found */}
                        {getLocale("noSell")}{" "}
                      </div>
                    )
                  ) : (
                    <div className="bg-transparent border border-gray-600 rounded-lg p-4 w-[90%] mx-auto text-black font-medium text-lg mt-6">
                      {/* No any sell order found */}
                      {getLocale("noSell")}{" "}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PopUp
        setOpen={setGetAll}
        open={getAll}
        title={"Verify your orders"}
        height={100}
      >
        <div className=" h-full w-full bg-bgWhite rounded-tl-xl rounded-tr-xl py-4 z-10 ">
          <div className=" flex justify-between items-center px-4 ">
            <div className="flex justify-between text-[16px] leading-[140%]  px-0.5 py-0.5 items-center border h-[35px] min-w-[160px] rounded-md overflow-hidden  ">
              <button
                onClick={() => setIsActive2(true)}
                className={` transition-all ${
                  isActive2 ? "bg-bgBlue text-white" : ""
                } rounded-md w-1/2 h-full`}
              >
                {/* Ongoing */}
                {getLocale("ongoing")}
              </button>
              <button
                onClick={() => setIsActive2(false)}
                className={` transition-all px-2 py-1 ${
                  isActive2 ? "" : "bg-bgBlue text-white"
                } rounded-md w-1/2 h-full`}
              >
                {/* Fulfilled */}
                {getLocale("fullfilled")}
              </button>
            </div>
          </div>
          <div className=" relative bg-bgWhite">
            <div
              className={` bg-bgWhite max-h-[75vh] overflow-y-auto w-full left-0 top-0 absolute transition-all py-2 no-scrollbar`}
            >
              {data2?.length > 0 ? (
                data2?.map((item, index) => (
                  <P2PCard
                    isVerify={true}
                    setRateNow={setRateNow}
                    setPayload={setPayload}
                    confirmOrder={(id) => confirmOrder(id)}
                    data={item}
                    buy={true}
                    key={`buy-${index}`}
                    item={item}
                  />
                ))
              ) : (
                <div className="bg-transparent border border-gray-600 rounded-lg p-4 w-[90%] mx-auto text-black font-medium text-lg mt-6">
                  No any sell order found{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </PopUp>
      <PopUp
        setOpen={setRateNow}
        open={isRateNow}
        title={"Rate Now"}
        height={100}
      >
        <div className="max-w-md mx-auto p-4 bg-white  rounded-md">
          {/* <h2 className="text-xl font-semibold text-gray-800 mb-4">Feedback</h2> */}

          <textarea
            className="w-full p-2 border resize-none outline-none  border-gray-300 rounded-md "
            placeholder="Write your feedback..."
            rows="4"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          ></textarea>

          <div className="flex items-center space-x-1 mb-4  justify-center mt-2">
            {renderStars()}
          </div>
          <button
            className="mt-4 w-full bg-bgBlue text-white py-2 px-4 rounded-md transition"
            onClick={() => handleRating()}
          >
            {/* Submit Feedback */}
            {getLocale("submitFeedback")}
          </button>
        </div>
      </PopUp>
    </div>
  );
};

const P2PCard = ({ data, confirmOrder, isVerify, setRateNow, setPayload }) => {
  const [isCopied, setIsCopied] = useState(true);
  const navigate = useNavigate();

  return (
    <div>
      <div className="px-4 border-b border-t border-borderGray py-2">
        <div className=" flex items-center justify-between gap-1 mb-1">
          <p className=" text-[13px] tracking-[1.02px] leading-[145%] font-semibold">
            {data?.type} {data?.coin?.name}
          </p>
          <div className="bg-[#f0f0f0] rounded-lg px-2 py-1 text-sm text-black">
            {data?.orderStatus}
          </div>
        </div>

        <div className=" flex justify-between items-start mt-1">
          <p className="text-base font-normal text-black opacity-60"> Amount</p>
          <p className="text-base font-normal text-black ">
            {" "}
            <NumericFormat
              decimalScale={2}
              value={data?.offerId?.amount}
              displayType="text"
              thousandSeparator
            />
          </p>
        </div>
        <div className=" flex justify-between items-start mt-1">
          <p className="text-base font-normal text-black opacity-60"> Price</p>
          <p className="text-base font-normal text-black ">
            {" "}
            {data?.totalPaymentInFait ?? 0}
          </p>
        </div>
        <div className=" flex justify-between items-start mt-1">
          <p className="text-base font-normal text-black opacity-60">
            {" "}
            {/* Quantity */}
            {getLocale("quantity")}
          </p>
          <p className="text-base font-normal text-black ">
            {" "}
            <NumericFormat
              decimalScale={2}
              value={data?.totalAmountCoin}
              displayType="text"
              thousandSeparator
            />
          </p>
        </div>
        <div className=" flex justify-between items-start mt-1">
          <p className="text-base font-normal text-black opacity-60"> Order</p>
          <p className="text-base font-normal text-black flex items-center gap-2 ">
            {isCopied ? (
              <IconCopy
                onClick={() => {
                  navigator.clipboard.writeText(data?._id);
                  setIsCopied(false);
                  setTimeout(() => {
                    setIsCopied(true);
                  }, 1000);
                }}
                size={15}
                className=" cursor-pointer"
              />
            ) : (
              <IconCheck
                onClick={() => {
                  navigator.clipboard.writeText(data?._id);
                }}
                size={15}
                className=" cursor-pointer text-green-500"
              />
            )}
            {data?._id}
          </p>
        </div>
        <div className=" flex justify-between items-start mt-1">
          <p className="text-base font-normal text-black opacity-60"> </p>
          <p className="text-sm font-normal text-black ">
            {" "}
            {moment(data?.createdAt)?.format("DD-MM-YYYY hh:mm:ss A")}
          </p>
        </div>
        {isVerify ? (
          <>
            <button
              className=" bg-bgBlue w-full h-[35px] rounded-md text-textWhite disabled:bg-bgGray"
              onClick={() => {
                navigate("/verifyOrder", {
                  state: { response: data, ID: data?.offerId?._id },
                });
              }}
            >
              {/* View Order */}
              {getLocale("viewOrder")}
            </button>

            <button
              disabled={data?.orderStatus !== "COMPLETED"}
              className=" mt-2 bg-yellow-500 w-full h-[35px] disabled:bg-bgGray rounded-md text-textWhite"
              onClick={() => {
                setRateNow(true);
                setPayload({ ...data, sellerFlag: false });
              }}
            >
              {/* Rate now */}
              {getLocale("rate")}
            </button>
          </>
        ) : (
          <>
            <button
              // disabled={
              //   data?.orderStatus === "COMPLETED" ||
              //   data?.orderStatus === "CANCELED"
              // }
              className=" bg-bgBlue w-full h-[35px] rounded-md text-textWhite disabled:bg-bgGray"
              onClick={() => {
                navigate(ROUTES.VERIFYORDERTOSELL, {
                  state: { response: data, ID: data?.offerId?._id },
                });
              }}
            >
              {/* View order */}
              {getLocale("viewOrder")}
            </button>
            <button
              disabled={data?.orderStatus !== "COMPLETED"}
              className=" mt-2 bg-yellow-500 w-full h-[35px] disabled:bg-bgGray rounded-md text-textWhite"
              onClick={() => {
                setRateNow(true);
                setPayload({ ...data, sellerFlag: true });
              }}
            >
              {/* Rate now */}
              {getLocale("rate")}
            </button>
          </>
        )}
      </div>
    </div>
  );
};
export default P2POrders;
