import { IconArrowNarrowLeft } from "@tabler/icons-react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import qrCode from "../../Assets/image/QrCode.png";
import share from "../../Assets/image/share.png";
import {
  cancleOrder,
  getGraph,
  getOrders,
  getTradeHistory,
  getTradingPair,
  getTradingPairOrder,
} from "../../service/api";
import moment from "moment";
import { ROUTES } from "../../constants/routes";
import { NumberFormatBase, NumericFormat } from "react-number-format";
import { getLocale } from "../../constants/language";

function PairCoin() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [pairData, setPairData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("TRADE");
  const [subActive, setSubActive] = useState("fullFill");
  const [cancelLoading, setCancelLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isBuyOpen, setBuyIsOpen] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");
  const [pairSelected, setPairSelected] = useState("");
  const dropdownRef = useRef(null);
  const pairDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setBuyIsOpen(false);
      }
      if (
        pairDropdownRef.current &&
        !pairDropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getData = () => {
    let params = {
      page: 1,
      sizePerPage: 100,
      pagination: false,
      orderStatus:
        activeTab === "TRANSACTION" && subActive === "Cancel"
          ? "CANCELLED"
          : "OPEN",
    };
    if (pairSelected) {
      params.pairId = pairSelected?._id;
    }
    if (orderStatus) {
      params.orderType = orderStatus.toUpperCase();
    }
    setLoading(true);
    getTradingPairOrder(params)
      ?.then((res) => {
        if (res?.status) {
          setData(res?.data?.result);
        }
      })
      .catch((e) => {})
      .finally(() => setLoading(false));
  };

  const getTradeHistoryData = () => {
    let params = { page: 1, sizePerPage: 100, pagination: false };
    if (pairSelected) {
      params.pairId = pairSelected?._id;
    }
    if (orderStatus) {
      params.orderType = orderStatus.toUpperCase();
    }
    setLoading(true);
    getTradeHistory(params)
      ?.then((res) => {
        if (res?.status) {
          setData(res?.data?.result);
        }
      })
      .catch((e) => {})
      .finally(() => setLoading(false));
  };

  const getPairData = () => {
    getTradingPair({ page: 1, sizePerPage: 100, pagination: false })?.then(
      (res) => {
        if (res?.status) {
          setPairData(res?.data?.result);
        }
      }
    );
  };

  useEffect(() => {
    if (activeTab === "TRADE") {
      setData([]);
      getData();
    } else {
      setData([]);
      if (subActive === "Cancel") {
        getData();
      } else {
        getTradeHistoryData();
      }
    }
    const currentDate = moment();

    const endDate = moment().subtract(1, "days");
    getGraph({
      pairId: "670ea181d96301f59e3ffb7e",
      // startDate: new Date(new Date().getTime() - 6060 * 60 * 1000),
      // endDate: new Date(new Date().getTime() - 60 * 60 * 1000),
    }).then((res) => {});
  }, [activeTab, pairSelected, orderStatus, subActive]);

  useEffect(() => {
    getPairData();
  }, []);

  const handleCancel = (id) => {
    if (cancelLoading) {
      return;
    }
    let body = {
      orderStatus: "CANCELLED",
    };
    setCancelLoading(true);
    cancleOrder(id, body)
      ?.then((res) => {
        if (res?.status) {
          getData();
        }
      })
      .catch((e) => {})
      .finally(() => setCancelLoading(false));
  };

  const Skeleton = () => {
    return (
      <div className="flex justify-between items-center border-b border-[#6a6a6a] cursor-pointer hover:bg-[#f0f0f0] py-4 animate-pulse">
        <div className="text-left space-y-2">
          <div className="w-32 h-4 bg-slate-200 rounded"></div>
          <div className="w-24 h-3 bg-slate-200 rounded"></div>
        </div>
        <div className="text-right space-y-2">
          <div className="w-20 h-4 bg-slate-200 rounded"></div>
          <div className="w-16 h-3 bg-slate-200 rounded"></div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col">
        <div className="flex justify-between items-center">
          <IconArrowNarrowLeft
            className="size-7 cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <p>
            {/* Pair Orders */}
            {getLocale("pairOrders")}
          </p>
        </div>
        <div className="mt-5">
          {/* <h2 className="text-[22px] font-medium text-[#111111] leading-[145%] tracking-[-1px] text-left">
            // My Orders
            {getLocale("myOrders")}
          </h2> */}

          <div></div>

          <div className=" flex justify-center items-center mt-1">
            <div
              className={`flex justify-center items-center w-full border border-[#111]/[0.2] rounded-md  md:h-[45px] h-[80px] font-medium text-base p-0.5 transform transition-transform duration-1000 `}
            >
              <button
                className={`${
                  activeTab === "TRADE"
                    ? "bg-[#111] text-white"
                    : "bg-transparent text-[#111]"
                } px-2 rounded-md py-1 cursor-pointer w-full h-full`}
                onClick={() => {
                  setPairSelected("");
                  setIsOpen(false);
                  setOrderStatus("");
                  setBuyIsOpen(false);
                  setActiveTab("TRADE");
                }}
              >
                {/* Trade Transactions */}
                {getLocale("tradeTransactions")}
              </button>
              <button
                className={`${
                  activeTab === "TRANSACTION"
                    ? "bg-[#111] text-white"
                    : "bg-transparent text-[#111]"
                } px-2 rounded-md py-1 cursor-pointer w-full h-full`}
                onClick={() => {
                  setPairSelected("");
                  setIsOpen(false);
                  setOrderStatus("");
                  setBuyIsOpen(false);
                  setActiveTab("TRANSACTION");
                }}
              >
                {/* Trade History */}
                {getLocale("tradeHistory")}
              </button>
            </div>
          </div>

          {activeTab === "TRANSACTION" && (
            <div className="flex items-center justify-start gap-2 mt-2 ">
              <div
                className={`${
                  subActive === "fullFill" ? "bg-gray-200" : "bg-transparent"
                } border border-gray-400 rounded-full py-1 px-2 text-sm cursor-pointer`}
                onClick={() => {
                  setSubActive("fullFill");
                }}
              >
                {/* Fullfilled */}
                {getLocale("fullfilled")}
              </div>
              <div
                className={`${
                  subActive === "Cancel" ? "bg-gray-200" : "bg-transparent"
                } border border-gray-400 rounded-full py-1 px-2 text-sm cursor-pointer`}
                onClick={() => {
                  setSubActive("Cancel");
                }}
              >
                {/* Cancelled */}
                {getLocale("cancelled")}
              </div>
            </div>
          )}

          <div className="flex items-center justify-start gap-2">
            <div className="relative w-fit mt-4 text-sm" ref={pairDropdownRef}>
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="w-full px-2 py-1 text-left bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none flex justify-between items-center gap-3"
              >
                {pairSelected
                  ? `${pairSelected?.fromCoin?.symbol}/${pairSelected?.toCoin?.symbol}`
                  : getLocale("filterByPair")}
                <div className="flex items-center justify-center gap-2">
                  {pairSelected && (
                    <span
                      className="float-right rotate-0 "
                      onClick={(e) => {
                        e.stopPropagation();
                        setPairSelected("");
                      }}
                    >
                      x
                    </span>
                  )}
                  <span className="float-right rotate-180">^</span>
                </div>
              </button>

              {isOpen && (
                <div className="absolute z-10 w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg text-sm">
                  {pairData && pairData?.length > 0 ? (
                    pairData?.map((obj) => {
                      return (
                        <button
                          onClick={() => {
                            setPairSelected(obj);
                            setIsOpen(false);
                          }}
                          className="w-full px-4 py-2 text-left hover:bg-gray-100"
                        >
                          {obj?.fromCoin?.symbol}
                          {obj?.toCoin?.symbol}
                        </button>
                      );
                    })
                  ) : (
                    <button
                      onClick={() => {}}
                      className="w-full px-4 py-2 text-left hover:bg-gray-100"
                    >
                      {/* No pair found */}
                      {getLocale("noPair")}
                    </button>
                  )}
                </div>
              )}
            </div>
            {activeTab === "TRANSACTION" && (
              <div ref={dropdownRef} className="relative w-fit mt-4 text-sm">
                <button
                  onClick={() => setBuyIsOpen(!isBuyOpen)}
                  className="w-full px-2 py-1 text-left bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none flex justify-between items-center gap-3"
                >
                  {orderStatus ? orderStatus : getLocale("filterByStatus")}
                  <div className="flex items-center justify-center gap-2">
                    {orderStatus && (
                      <span
                        className="float-right rotate-0"
                        onClick={(e) => {
                          e.stopPropagation();
                          setOrderStatus("");
                        }}
                      >
                        x
                      </span>
                    )}
                    <span className="float-right rotate-180">^</span>
                  </div>
                </button>

                {isBuyOpen && (
                  <div className="absolute z-10 w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg text-sm">
                    <button
                      onClick={() => {
                        setOrderStatus("Buy");
                        setBuyIsOpen(false);
                      }}
                      className="w-full px-4 py-1 text-left hover:bg-gray-100"
                    >
                      {/* Buy */}
                      {getLocale("buy")}
                    </button>
                    <button
                      onClick={() => {
                        setOrderStatus("Sell");
                        setBuyIsOpen(false);
                      }}
                      className="w-full px-4 py-1 text-left hover:bg-gray-100"
                    >
                      {/* Sell */}
                      {getLocale("sell")}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          {activeTab === "TRADE" ? (
            <div className="mt-3">
              {loading ? (
                Array.from({ length: 4 }).map((_, index) => (
                  <Skeleton key={index} />
                ))
              ) : data && data.length > 0 ? (
                data.map((obj, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center border border-[#d1d1d1] rounded-lg cursor-pointer hover:bg-[#f9f9f9] shadow-sm p-4 mb-3 transition-all duration-200"
                    onClick={() =>
                      navigate(`/PairOrderStatus/${obj?._id}?Open`)
                    }
                  >
                    <div className="flex items-center gap-3">
                      <div
                        className={`p-2 rounded-full ${
                          obj?.orderType === "BUY"
                            ? "bg-green-200"
                            : "bg-red-200"
                        }`}
                      >
                        {obj?.orderType === "BUY" ? (
                          <i className="text-green-600 fas fa-arrow-up"></i>
                        ) : (
                          <i className="text-red-600 fas fa-arrow-down"></i>
                        )}
                      </div>

                      <div className="text-left">
                        <h1 className="text-[16px] font-medium leading-[140%] tracking-[0.2px] text-[#111111]">
                          {obj?.orderType} {obj?.fromCoin?.symbol}/
                          {obj?.toCoin?.symbol}
                        </h1>
                        <p className="text-[12px] leading-[145%] tracking-normal text-[#707070] mt-1">
                          {moment(obj?.createdAt)?.format("DD/MM/YYYY hh:mm A")}
                        </p>
                        <div
                          className={`text-[12px] w-fit px-2 text-[#fff] mt-1 ${
                            obj?.orderStatus === "CANCELLED"
                              ? "bg-red-500"
                              : "bg-gray-500"
                          } rounded-2xl`}
                        >
                          {obj?.orderStatus}
                        </div>
                      </div>
                    </div>

                    <div className="text-right">
                      {obj?.type === "LIMIT" ? (
                        <>
                          {" "}
                          <h1 className="text-[16px] font-bold leading-[140%] tracking-[0.2px] text-[#111111]">
                            <NumericFormat
                              value={obj?.pendingQuantity}
                              displayType="text"
                              decimalScale={4}
                              thousandSeparator
                            />{" "}
                            {obj?.fromCoin?.symbol}
                          </h1>
                          <p className="text-[14px] leading-[145%] tracking-normal text-[#707070]">
                            {parseFloat(obj?.pendingAmount).toFixed(2)}{" "}
                            {obj?.toCoin?.symbol}
                          </p>
                        </>
                      ) : (
                        <h1 className="text-[16px] font-bold leading-[140%] tracking-[0.2px] text-[#111111]">
                          {obj?.orderType === "BUY"
                            ? obj?.quantity
                              ? `${obj?.quantity} ${obj?.fromCoin?.symbol}`
                              : `${obj?.amount} ${obj?.toCoin?.symbol}`
                            : obj?.quantity
                            ? `${obj?.quantity} ${obj?.toCoin?.symbol}`
                            : `${obj?.amount} ${obj?.fromCoin?.symbol}`}
                        </h1>
                      )}
                      {obj?.type === "LIMIT" &&
                        obj?.orderStatus !== "CANCELLED" && (
                          <div
                            onClick={() => handleCancel(obj?._id)}
                            className="text-[12px] text-right float-right w-fit px-2 hover:text-[#fff] mt-1 hover:bg-gray-500 rounded-2xl"
                          >
                            {/* Cancel Order */}
                            {getLocale("candelOrder")}
                          </div>
                        )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="bg-transparent border border-gray-600 rounded-lg p-4 w-[90%] mx-auto text-black font-medium text-lg mt-6">
                  {/* No any order found */}
                  {getLocale("noOrder")}
                </div>
              )}
            </div>
          ) : (
            <div className="mt-3">
              {loading ? (
                Array.from({ length: 4 }).map((_, index) => (
                  <Skeleton key={index} />
                ))
              ) : data && data.length > 0 ? (
                data.map((obj, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center border border-[#d1d1d1] rounded-lg cursor-pointer hover:bg-[#f9f9f9] shadow-sm p-4 mb-3 transition-all duration-200"
                    onClick={() =>
                      navigate(`/PairOrderStatus/${obj?._id}?Closed`)
                    }
                  >
                    <div className="flex items-center gap-3">
                      <div
                        className={`p-2 rounded-full ${
                          obj?.orderId?.orderType === "BUY"
                            ? "bg-green-200"
                            : "bg-red-200"
                        }`}
                      >
                        {obj?.orderId?.orderType === "BUY" ? (
                          <i className="text-green-600 fas fa-arrow-up"></i>
                        ) : (
                          <i className="text-red-600 fas fa-arrow-down"></i>
                        )}
                      </div>

                      <div className="text-left">
                        <h1 className="text-[16px] font-medium leading-[140%] tracking-[0.2px] text-[#111111]">
                          {obj?.orderType} {obj?.fromCoin?.symbol}/
                          {obj?.toCoin?.symbol}
                        </h1>
                        <p className="text-[12px] leading-[145%] tracking-normal text-[#707070] mt-1">
                          {moment(obj?.createdAt)?.format("DD/MM/YYYY hh:mm A")}
                        </p>
                        <div
                          className={`text-[12px] w-fit px-2 text-[#fff] mt-1 ${
                            obj?.orderStatus === "CANCELLED"
                              ? "bg-red-500"
                              : "bg-gray-500"
                          } rounded-2xl`}
                        >
                          {obj?.orderStatus}
                        </div>
                      </div>
                    </div>

                    <div className="text-right">
                      {obj?.type === "LIMIT" ? (
                        <>
                          {" "}
                          <h1 className="text-[16px] font-bold leading-[140%] tracking-[0.2px] text-[#111111]">
                            {obj?.quantity}{" "}
                            {obj?.orderId?.orderType === "BUY"
                              ? obj?.fromCoin?.symbol
                              : obj?.toCoin?.symbol}
                          </h1>
                          <p className="text-[14px] leading-[145%] tracking-normal text-[#707070]">
                            ${parseFloat(obj?.amount).toFixed(2)}
                          </p>
                        </>
                      ) : (
                        <h1 className="text-[16px] font-bold leading-[140%] tracking-[0.2px] text-[#111111]">
                          {obj?.orderId?.orderType === "BUY"
                            ? obj?.quantity
                              ? `+${obj?.quantity?.toFixed(4)} ${
                                  obj?.fromCoin?.symbol
                                }`
                              : `-${obj?.amount?.toFixed(4)} ${
                                  obj?.toCoin?.symbol
                                }`
                            : obj?.quantity
                            ? `-${obj?.quantity?.toFixed(4)} ${
                                obj?.fromCoin?.symbol
                              }`
                            : `+${obj?.amount?.toFixed(4)} ${
                                obj?.toCoin?.symbol
                              }`}
                        </h1>
                      )}
                      {obj?.type === "LIMIT" &&
                        obj?.orderStatus !== "CANCELLED" && (
                          <div
                            onClick={() => handleCancel(obj?._id)}
                            className="text-[12px] text-right float-right w-fit px-2 hover:text-[#fff] mt-1 hover:bg-gray-500 rounded-2xl"
                          >
                            {/* Cancel Order */}
                            {getLocale("cancelOrder")}
                          </div>
                        )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="bg-transparent border border-gray-600 rounded-lg p-4 w-[90%] mx-auto text-black font-medium text-lg mt-6">
                  {/* No any history found */}
                  {getLocale("noHistory")}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PairCoin;
