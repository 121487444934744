import React from "react";
import logo from "../../Assets/image/Logo3.png";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../i18n";
import actions from "../../redux/user/actions";

const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state?.user);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      {" "}
      <div className="flex items-center justify-end gap-2 p-2">
        <div className="flex items-center justify-center border mt-2 bg-[#101010] opacity-95 w-fit rounded-full p-1">
          <div
            onClick={() => {
              changeLanguage(language === "sp" ? "en" : "es");
              dispatch(actions.setLangauge(language === "sp" ? "en" : "sp"));
            }}
            className={`${
              language === "en"
                ? "bg-white text-black"
                : "bg-transparent text-white"
            } text-xs font-bold p-1 rounded-full cursor-pointer w-[30px]`}
          >
            EN
          </div>
          <div
            onClick={() => {
              changeLanguage(language === "sp" ? "en" : "es");
              dispatch(actions.setLangauge(language === "sp" ? "en" : "sp"));
            }}
            className={`${
              language === "sp"
                ? "bg-white text-black"
                : "bg-transparent text-white"
            } text-xs font-bold p-1 rounded-full cursor-pointer w-[30px]`}
          >
            ES
          </div>
        </div>
      </div>
      <div className="bg-gradient-to-b min-h-[100vh] from-bgBlue to-[#1E1E1E] w-full h-full relative">
        <img
          src={logo}
          alt=""
          className="absolute left-[50%] top-[40%] transform -translate-x-[50%] -translate-y-[40%] "
        />
        <div className="absolute bottom-[100px] w-full">
          <button
            onClick={() => navigate(ROUTES.REGISTER)}
            className="w-[90%] px-2 py-3 mx-auto bg-white rounded-lg text-textDefault text-lg font-[700]"
          >
            {getLocale("register")}
          </button>
          <button
            onClick={() => navigate(ROUTES.LOGIN)}
            className="w-[90%] px-2 py-3 mx-auto bg-bgBlue text-white rounded-lg mt-4 text-lg font-[700]"
          >
            {getLocale("login")}
          </button>
        </div>
      </div>
    </>
  );
};

export default HomePage;
