import { IconArrowNarrowLeft, IconInfoCircle } from "@tabler/icons-react";
import { getLocale } from "../../../constants/language";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addMessage,
  cancelP2POrder,
  confirmP2POrder,
  createChat,
  getAllPayMethodByUser,
  getChatById,
  getP2PById,
  getp2pTrxById,
  verifyP2POrder,
} from "../../../service/api";
import { Danger, Success, Confrim } from "../../Core/Errors";
import { NumericFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/user/actions";
import Countdown from "./Counter";
import ChatModal from "./ChatModal";
import { ROUTES } from "../../../constants/routes";

function VerifyOrder() {
  const { orderChatId, user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const response = useLocation().state?.response;
  const ID = useLocation().state?.ID;
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [chatActive, setChatActive] = useState(false);
  const [data, setData] = useState([]);
  const [fields, setFields] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [seconds, setSeconds] = useState();
  const [chatId, setChatId] = useState("");
  const [messages, setMessages] = useState([]);
  const [trxdetails, setTrxDetails] = useState({});

  const getTrxById = () => {
    try {
      setLoader(true);
      getp2pTrxById(response?._id)
        .then((res) => {
          setTrxDetails({ ...res?.data });
          setLoader(false);
        })
        .catch((error) => {
          Danger(error?.response?.data?.message);
          setLoader(false);
        });
    } catch (error) {
      Danger(error?.response?.data?.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (trxdetails?.paymentStatus !== "COMPLETED") {
      const fetchTransaction = () => {
        getTrxById();
      };

      fetchTransaction();

      const intervalId = setInterval(fetchTransaction, 10000);

      return () => clearInterval(intervalId);
    }
  }, [trxdetails]);

  const getAllPaymethods = () => {
    try {
      setLoader(true);
      getAllPayMethodByUser(pageNumber, true, sizePerPage)
        .then((res) => {
          setData(res?.data?.result);
          setLoader(false);
        })
        .catch((error) => {
          Danger(error?.response?.data?.message);
          setLoader(false);
        });
    } catch (error) {
      Danger(error?.response?.data?.message);
      setLoader(false);
    }
  };
  const confirmOrder = (id) => {
    Confrim("Are you sure you want to verify this payment").then(
      async (value) => {
        switch (value) {
          case "cancel":
            break;
          case "catch":
            try {
              setLoader(true);
              try {
                confirmP2POrder(id)
                  .then((res) => {
                    Success(res?.message).then((_) => {
                      navigate(ROUTES.P2PHOME);
                      window.location.reload();
                    });
                    setLoader(false);
                  })
                  .catch((error) => {
                    Danger(error?.response?.data?.message);
                    setLoader(false);
                  });
              } catch (error) {
                Danger(error?.response?.data?.message);
                setLoader(false);
              }
            } catch (error) {}
            break;

          default:
            break;
        }
      }
    );
  };
  useEffect(() => {
    getTrxById();
    getAllPaymethods();
  }, []);
  const getP2PByid = () => {
    try {
      setLoader(true);
      getP2PById(ID)
        .then((res) => {
          if (!localStorage.getItem("leftTime")) {
            sessionStorage.setItem(
              "leftTime",
              res?.data?.paymentTimeLimitMinutes
            );
          }
          setFields(res?.data);
          setLoader(false);
          setSeconds(res?.data?.paymentTimeLimitMinutes);
        })
        .catch((error) => {
          Danger(error?.response?.data?.message);
          setLoader(false);
        });
    } catch (error) {
      Danger(error?.response?.data?.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (ID) getP2PByid();
  }, [ID]);

  const verifyOrder = () => {
    try {
      setLoader(true);
      try {
        verifyP2POrder(response?._id)
          .then((res) => {
            Success(res?.message).then((_) => {
              dispatch(actions.removeP2POrder(null));
              navigate(ROUTES.P2PHOME);
            });
            setLoader(false);
          })
          .catch((error) => {
            Danger(error?.response?.data?.message);
            setLoader(false);
          });
      } catch (error) {
        Danger(error?.response?.data?.message);
        setLoader(false);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(interval); // Clear interval on component unmount
    }
  }, [seconds]);

  const formatTime = (secs) => {
    const mins = Math.floor(secs / 60);
    const remainingSecs = secs % 60;
    return `${String(mins).padStart(2, "0")}:${String(remainingSecs).padStart(
      2,
      "0"
    )}`;
  };

  const cancel = () => {
    Confrim(
      "If you have already paid the seller, please do not cancel the order. If you voluntarily cancel your order or exceed the time limit 3 times in a day, your order function will be disabled for the remainder of the day, and your personal completion rate will be affected. However, if the order is canceled due to the seller's reasons, the system will re-evaluate the situation. View more"
    ).then(async (value) => {
      switch (value) {
        case "cancel":
          break;
        case "catch":
          setLoader(true);
          try {
            cancelP2POrder(response?._id)
              .then((res) => {
                Success(res?.message).then((_) => {
                  navigate(ROUTES.P2PHOME);
                });
                setLoader(false);
              })
              .catch((error) => {
                Danger(error?.response?.data?.message);
                setLoader(false);
              });
          } catch (error) {
            Danger(error?.response?.data?.message);
            setLoader(false);
          }
          break;

        default:
          break;
      }
    });
  };
  const timeOutcancel = () => {
    setLoader(true);
    // try {
    //   cancelP2POrder(response?._id)
    //     .then((res) => {
    //       Success(res?.message).then((_) => {
    //         navigate("/P2PHOME");
    //       });
    //       setLoader(false);
    //     })
    //     .catch((error) => {
    //       Danger(error?.response?.data?.message);
    //       setLoader(false);
    //     });
    // } catch (error) {
    //   Danger(error?.response?.data?.message);
    //   setLoader(false);
    // }
  };

  const handlecreateChat = () => {
    try {
      setLoader(true);
      try {
        createChat({ order: trxdetails?._id })
          .then((res) => {
            setMessages(res?.data?.[0]?.messages);
            setChatActive(true);
            setLoader(false);
            setChatId(res?.data[0]?._id);
            dispatch(actions.addChatId(res?.data[0]?._id));
          })
          .catch((error) => {
            Danger(error?.response?.data?.message);
            setLoader(false);
          });
      } catch (error) {
        Danger(error?.response?.data?.message);
        setLoader(false);
      }
    } catch (error) {}
  };
  const handleAddMessage = (message) => {
    try {
      setLoader(true);
      try {
        addMessage(orderChatId, { message: message })
          .then((res) => {
            getChatById(orderChatId).then((message) => {
              setMessages(message?.data?.[0]?.messages);
            });
            setLoader(false);
          })
          .catch((error) => {
            Danger(error?.response?.data?.message);
            setLoader(false);
          });
      } catch (error) {
        Danger(error?.response?.data?.message);
        setLoader(false);
      }
    } catch (error) {}
  };
  return (
    <div>
      {/* {loader && <Loader />} */}
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col ">
        <div>
          <div className=" flex justify-between items-center">
            <IconArrowNarrowLeft
              className="size-7 cursor-pointer"
              onClick={() => navigate(ROUTES.P2PORDER, { state: { ID: ID } })}
            />
            <h1 className="font-medium leading-[145%] tracking-[-1px] text-[18px] text-textPrime"></h1>
            {!(
              trxdetails?.paymentStatus === "COMPLETED" ||
              trxdetails?.orderStatus === "COMPLETED" ||
              trxdetails?.paymentStatus === "CANCELED" ||
              trxdetails?.orderStatus === "CANCELED"
            ) && (
              <p
                onClick={cancel}
                className=" underline hover:opacity-50 text-[16px] text-textPrime cursor-pointer"
              >
                {/* Cancel the order */}
                {getLocale("cancelOrder")}
              </p>
            )}
          </div>
        </div>

        <div className=" mt-5 ">
          <div>
            <h1 className=" text-[28px] text-textPrime  leading-3 tracking-wide text-left">
              {/* Verify with Counterparty */}
              {getLocale("verifyCounterparty")}
            </h1>
            {!(
              trxdetails?.paymentStatus === "COMPLETED" ||
              trxdetails?.orderStatus === "COMPLETED" ||
              trxdetails?.paymentStatus === "CANCELED" ||
              trxdetails?.orderStatus === "CANCELED"
            ) && (
              <p className=" flex text-[12px] mt-3 opacity-90 gap-1">
                {/* Verify and pay within */}
                {getLocale("verifyWithin")}{" "}
                <>
                  {fields?.paymentTimeLimitMinutes && (
                    <Countdown
                      cancelOrder={(id) => timeOutcancel(id)}
                      initialSeconds={fields?.paymentTimeLimitMinutes * 60}
                    />
                  )}{" "}
                </>
                {/* or the order will be canceled */}
                {getLocale("orderWillCancel")}
              </p>
            )}
          </div>
          <div className=" rounded-md border px-2 mt-5 py-2">
            <div className=" flex justify-between items-center ">
              <div className="flex gap-1 items-center ">
                <p className=" h-6 w-6 rounded-full border text-[12px] flex items-center justify-center uppercase bg-bgBlue text-textWhite px-1 py-1 ">
                  {trxdetails?.buyerId?._id !== user?._id
                    ? trxdetails?.buyerId?.firstName?.slice(0, 1)
                    : fields?.sellerId?.firstName?.slice(0, 1)}
                </p>
                <p className=" uppercase text-[16px]   ">
                  {trxdetails?.buyerId?._id !== user?._id
                    ? trxdetails?.buyerId?.firstName
                    : fields?.sellerId?.firstName}
                </p>
              </div>
              <div>
                <button
                  disabled={!fields?._id}
                  onClick={() => handlecreateChat()}
                  className=" px-1 w-16 py-1 bg-bgBlue text-textWhite text-[16px] font-semibold rounded-sm "
                >
                  {/* Chat */}
                  {getLocale("chat")}
                </button>
              </div>
            </div>
            <div>
              <p className=" text-[10px] text-left text-textPrime  mt-2">
                {/* Escrewed Crypto */}
                {getLocale("escrewedCrypto")}
              </p>
            </div>
          </div>
          <div className=" rounded-md border px-2 py-2 mt-4">
            <div>
              <p className=" flex items-center gap-1">
                <span className="text-green-500">
                  {trxdetails?.buyerId?._id === user?._id
                    ? trxdetails?.type
                    : trxdetails?.offerId?.type}
                  {/* {P2PorderPayload?.type == "BUY" ? "Buy" : "Sell"} */}
                </span>
                {fields?.coin?.symbol}
              </p>
              <div className=" flex justify-between items-center  mt-2">
                <p className=" flex  items-center gap-1 text-[14px] opacity-70 leading-3 tracking-wide">
                  {/* Fiat Amount  */}
                  {getLocale("fiatAmount")}
                  <IconInfoCircle size={15} />
                </p>
                <p className=" flex  items-center gap-1 text-[14px] opacity-100 leading-3 tracking-wide">
                  <NumericFormat
                    decimalScale={2}
                    displayType="text"
                    value={trxdetails?.totalPaymentInFait}
                  />
                </p>
              </div>
              <div className=" flex justify-between items-center  mt-2">
                <p className=" flex  items-center gap-1 text-[14px] opacity-70 leading-3 tracking-wide">
                  {/* Price */}
                  {getLocale("price")}
                </p>
                <p className=" flex  items-center gap-1 text-[14px] opacity-100 leading-3 tracking-wide">
                  {Number(fields?.price)}
                </p>
              </div>
              <div className=" flex justify-between items-center  mt-2">
                <p className=" flex  items-center gap-1 text-[12px] opacity-70 leading-3 tracking-wide">
                  {trxdetails?.buyerId?._id === user?._id
                    ? trxdetails?.type === "BUY"
                      ? "Received"
                      : "Payable"
                    : trxdetails?.type === "SELL"
                    ? "Received"
                    : "Payable"}{" "}
                  {/* Quantity */}
                  {getLocale("quantity")}
                </p>
                <p className=" flex  items-center gap-1 text-[14px] opacity-100 leading-3 tracking-wide">
                  {
                    <NumericFormat
                      decimalScale={2}
                      value={trxdetails?.totalAmountCoin}
                      displayType="text"
                    />
                  }
                </p>
              </div>
              <div className=" flex justify-between items-center  mt-2">
                <p className=" flex  items-center gap-1 text-[12px] opacity-70 leading-3 tracking-wide">
                  {/* Order */}
                  {getLocale("order")}
                </p>
                <p className=" select-all">{trxdetails?._id}</p>
              </div>
              <div className=" flex justify-between items-center  mt-2">
                <p className=" flex  items-center gap-1 text-[12px] opacity-70 leading-3 tracking-wide">
                  {/* Memo */}
                  {getLocale("memo")}
                </p>
                <p className=" select-all">{trxdetails?.offerId?.remarks}</p>
              </div>
            </div>
          </div>
          {fields?.type === "SELL" ? (
            <div className=" border px-2 py-2 mt-4 rounded-md ">
              <h2 className=" w-full text-left font-medium mt-2 text-[16px]">
                {/* Payment methods */}
                {getLocale("paymentMethod")}
              </h2>
              {fields?.userPaymentMethod?.map((item, index) => (
                <div className="rounded-md bg-opacity-10 w-full mt-2 flex mx-auto justify-between items-center">
                  <div>
                    <div className="text-[#111] text-left text-base mr-auto flex h-full items-center">
                      {/* <div className={`${item.color} w-[2px] h-3`}></div> */}
                      <p className=" uppercase text-[12px] font-semibold">
                        {item.type}
                      </p>
                    </div>
                    {Object.entries(item?.details ?? {}).map(
                      ([key, value], index) => (
                        <div className="text-left flex items-center gap-1">
                          <p className=" text-[12px] text-textPrime leading-[140%] tracking-[0.02px]">
                            {key}:
                          </p>
                          <p className=" text-[12px] text-textPrime leading-[140%] tracking-[0.02px] select-all">
                            {value}
                          </p>
                        </div>
                      )
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className=" border px-2 py-2 mt-4 rounded-md ">
              <h2 className=" w-full text-left font-medium mt-2 text-[16px]">
                {/* Payment methods */}
                {getLocale("paymentMethod")}
              </h2>
              {trxdetails?.userPaymentMethod &&
                trxdetails?.userPaymentMethod?.length > 0 &&
                trxdetails?.userPaymentMethod?.map((item, index) => (
                  <div className="rounded-md bg-opacity-10 w-full mt-2 flex mx-auto justify-between items-center">
                    <div>
                      <div className="text-[#111] text-left text-base mr-auto flex h-full items-center">
                        {/* <div className={`${item.color} w-[2px] h-3`}></div> */}
                        <p className=" uppercase text-[12px] font-semibold">
                          {item.type}
                        </p>
                      </div>
                      {Object.entries(item?.details ?? {}).map(
                        ([key, value], index) => (
                          <div className="text-left flex items-center gap-1">
                            <p className=" text-[12px] text-textPrime leading-[140%] tracking-[0.02px]">
                              {key}:
                            </p>
                            <p className=" text-[12px] text-textPrime leading-[140%] tracking-[0.02px] select-all">
                              {value}
                            </p>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>

        {!(
          // trxdetails?.paymentStatus === "COMPLETED" ||
          (
            trxdetails?.orderStatus === "COMPLETED" ||
            // trxdetails?.paymentStatus === "CANCELED" ||
            trxdetails?.orderStatus === "CANCELED"
          )
        ) && (
          <div className=" absolute bottom-[10px] left-0 w-full flex justify-center px-4  ">
            {trxdetails &&
            trxdetails?.paymentStatus !== "COMPLETED" &&
            trxdetails?.offerId?.type === "SELL" ? (
              trxdetails?.offerId?.sellerId?._id !== user?._id ? (
                <button
                  onClick={() => verifyOrder()}
                  className="  w-full rounded-md m-auto py-5 disabled:bg-bgGray text-center flex items-center justify-center gap-1 text-textWhite bottom-[0px] left-0  bg-bgBlue"
                >
                  <span>
                    {/* Verify order */}
                    {getLocale("verifyOrder")}
                  </span>
                </button>
              ) : (
                <button
                  disabled={
                    (trxdetails?.offerId?.type === "SELL" &&
                      trxdetails?.offerId?.sellerId?._id === user?._id) ||
                    trxdetails?.paymentStatus !== "COMPLETED"
                  }
                  onClick={() => confirmOrder(response?._id)}
                  className="  w-full rounded-md m-auto py-5 disabled:bg-bgGray text-center flex items-center justify-center gap-1 text-textWhite bottom-[0px] left-0  bg-bgBlue"
                >
                  <span>
                    {(trxdetails?.offerId?.type === "SELL" &&
                      trxdetails?.offerId?.sellerId?._id === user?._id) ||
                    trxdetails?.paymentStatus !== "COMPLETED"
                      ? "Start chat to verify"
                      : "Confirm order"}
                  </span>
                </button>
              )
            ) : trxdetails?.paymentStatus !== "COMPLETED" &&
              trxdetails?.buyerId?._id !== user?._id ? (
              <button
                className="  w-full rounded-md m-auto py-5 disabled:bg-bgGray text-center flex items-center justify-center gap-1 text-textWhite bottom-[0px] left-0  bg-bgBlue"
                onClick={() => verifyOrder()}
              >
                <span>Verify order</span>
              </button>
            ) : (
              <button
                disabled={
                  (trxdetails?.offerId?.type === "BUY" &&
                    trxdetails?.buyerId?._id !== user?._id) ||
                  trxdetails?.paymentStatus !== "COMPLETED"
                }
                onClick={() => confirmOrder(response?._id)}
                className="  w-full rounded-md m-auto py-5 disabled:bg-bgGray text-center flex items-center justify-center gap-1 text-textWhite bottom-[0px] left-0  bg-bgBlue"
              >
                <span>
                  {(trxdetails?.offerId?.type === "BUY" &&
                    trxdetails?.buyerId?._id !== user?._id) ||
                  trxdetails?.paymentStatus !== "COMPLETED"
                    ? "Start chat to verify"
                    : "Confirm order"}
                </span>
              </button>
            )}
          </div>
        )}
      </div>
      <ChatModal
        getMessage={handlecreateChat}
        setCurrFilter={setChatActive}
        currFilter={chatActive}
        messages={messages}
        addMessage={(message) => handleAddMessage(message)}
      />
    </div>
  );
}

export default VerifyOrder;
