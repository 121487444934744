import { IconArrowNarrowLeft, IconChevronRight } from "@tabler/icons-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLocale } from "../../../constants/language";
import { Button } from "@mui/material";
import { ROUTES } from "../../../constants/routes";
const pauseNotification = [
  {
    title: getLocale("disabled"),
    value: 1,
  },
  {
    title: getLocale("hours"),
    value: 2,
  },
  {
    title: getLocale("day"),
    value: 3,
  },
  {
    title: getLocale("week"),
    value: 4,
  },
];

function LiteNotification() {
  const navigate = useNavigate();
  const [isInfo, setIsInfo] = useState(false);
  const [active, setActive] = useState(0);
  return (
    <div>
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col ">
        <div className=" flex justify-between items-center">
          <IconArrowNarrowLeft
            className="size-7 cursor-pointer"
            onClick={() => navigate(ROUTES.P2PLITESETTINGS)}
          />
          <h2 className=" font-semibold leading-[140%] tracking-[-1px] "></h2>
          <p></p>
        </div>
        <div className=" mt-2 ">
          <h1 className=" text-left text-[36px] font-semibold tracking-[-1px] leading-[145%]">
            P2P Notifications
          </h1>
          <div className=" mt-6">
            <h1 className=" text-left text-[22px] font-semibold tracking-[-1px] leading-[145%]">
              Orders
            </h1>
            <p className=" text-left text-[14px] text-textPrime leading-[140%] ">
              To stay updated at new orders and chaalanges in order status.
              Please enable at least one notification. Note that you will only
              receives SMS notifications (if needed) when you are inactive on
              the KMBIO app
            </p>
          </div>
          <div className="mt-[20px]">
            <Notify title={"Email"} />
            <Notify title={"SMS "} />
            <Notify title={"App Notification"} />
            <Notify title={"Order Shordcuts"} />
          </div>
          <div className=" mt-6">
            <h1 className=" text-left text-[22px] font-semibold tracking-[-1px] leading-[145%]">
              Apeals
            </h1>
            <p className=" text-left text-[14px] text-textPrime leading-[140%] ">
              Enable at least one notification to receive updates on new appeals
              and apeal status changes
            </p>
          </div>
          <div className="mt-[20px]">
            <Notify title={"Email"} />
            <Notify title={"SMS "} />
            <Notify title={"App Notification"} />
          </div>
          <div className=" mt-6">
            <h1 className=" text-left text-[22px] font-semibold tracking-[-1px] leading-[145%]">
              Chat
            </h1>
            <p className=" text-left text-[14px] text-textPrime leading-[140%] ">
              Please enable notification to receive chat messages
            </p>
          </div>
          <div className="mt-[20px]">
            <Notify title={"App Notification"} />
          </div>

          <div className=" mt-6">
            <h1 className=" text-left text-[22px] font-semibold tracking-[-1px] leading-[145%]">
              Security
            </h1>
            <p className=" text-left text-[14px] text-textPrime leading-[140%] ">
              Security, privacy reminders, and other messgaes via email and text
              cannot be manually disabled
            </p>
          </div>
          <div className="mt-[20px]">
            <Notify title={"Email"} />
            <Notify title={"SMS "} />
            <Notify title={"App Notification"} />
          </div>
          <div className=" mt-6">
            <h1 className=" text-left text-[22px] font-semibold tracking-[-1px] leading-[145%]">
              Get App Notifications
            </h1>
            <p className=" text-left text-[14px] text-textPrime leading-[140%] ">
              Get personalized user and market alerts tailored to your interest
            </p>
          </div>
          <div className="mt-[20px]">
            <Notify
              title={"All App Notifications"}
              desc={
                "Enable this to control the custom notifications bellow individually"
              }
            />
          </div>
          <div className="pl-4">
            <Notify
              title={"Price Alert"}
              desc={"Get notified when the market price reaches your target"}
            />
            <Notify
              title={"Resuring Alert"}
              desc={
                "Receives trading reminders at your specified time and day "
              }
            />
            <Notify
              title={"Market Growth"}
              desc={
                "Get notified when the market trade growth is higher that usual "
              }
            />
            <Notify
              title={"Payment Method"}
              desc={
                "Get notified when the market trade growth is higher that usual "
              }
            />
            <Notify
              title={"Users you follow"}
              desc={
                "Get notified when the market trade growth is higher that usual "
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LiteNotification;

const Notify = ({ title, desc }) => {
  return (
    <div className=" mt-4 flex justify-between items-center rounded-md py-2 cursor-pointer gap-2">
      <div>
        <h1 className="text-[16px] leading-[140%] tracking-[0.2px] text-[#111111] font-normal text-left">
          {title}
        </h1>
        {desc && (
          <h1 className="text-[12px] opacity-80 leading-[140%] tracking-[0.2px] text-[#111111] font-normal text-left">
            {desc}
          </h1>
        )}
      </div>
      <label className="inline-flex items-center cursor-pointer gap-2 ">
        <input type="checkbox" value="" className="sr-only peer" />
        <div
          className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none
       peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer
       dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full
       peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px]
       after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all
       dark:border-gray-600 peer-checked:bg-black"
        ></div>
      </label>
    </div>
  );
};
