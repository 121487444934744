import React from "react";

import {
  IconArrowNarrowLeft,
  IconSearch,
  IconTrash,
} from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/user/actions";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { NumericFormat } from "react-number-format";

function Currencies({ setOPen, open }) {
  const { P2PLiteorderPayload } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <div className="h-full min-h-[100vh] relative bg-white font-sans pb-12 pt-4 flex flex-col ">
        <div className="">
          <div className=" px-4 w-full  items-center rounded-md flex justify-between gap-1">
            <IconArrowNarrowLeft
              className="  cursor-pointer "
              onClick={() => setOPen(false)}
              size={30}
            />
            <p className=" text-[14px] font-semibold opacity-80 ">
              Choose Currency
            </p>
            <p></p>
          </div>
          <div className="hidden px-4 flex justify-between items-center w-full  mt-4">
            <div className=" w-full bg-bgGray items-center rounded-md h-[30px] flex justify-between gap-1 pl-1">
              <IconSearch size={20} />
              <input
                type="text"
                className=" w-full h-full border-none outline-none placeholder:opacity-[0.5] bg-transparent "
                placeholder="Search Currency"
              />
            </div>
          </div>
          <div className="hidden px-4 mt-4">
            <div className=" w-full  items-center rounded-md h-[30px] flex justify-between gap-1">
              <p className=" text-[14px] font-semibold opacity-60 ">
                Search History
              </p>
              <IconTrash size={30} className=" cursor-pointer" />
            </div>
            {
              <div className=" mt-2">
                <p className=" w-full text-left  uppercase">usdt</p>
              </div>
            }
          </div>
          <div className=" mt-6 px-4 text-left text-textPrime opacity-[0.7]">
            <p>
              {/* P2P Supported Coins */}
              Top Search
            </p>
          </div>
          <div>
            {P2PLiteorderPayload?.coin?.fiatPairs?.map((item, index) => (
              <div
                key={item?.fiatCurrency}
                onClick={() => {
                  // setCurrFilter(false);
                  dispatch(
                    actions.setP2PLitePaylaod({
                      ...P2PLiteorderPayload,
                      fiatCurrency: item,
                    })
                  );
                  // navigate(ROUTES.LITEBUYENTERAMOUNT);
                  setOPen(false);
                }}
                className={`rounded-md   px-2 w-[98%] cursor-pointer mt-2 flex items-center justify-center h-[54px] mx-auto ${
                  P2PLiteorderPayload?.fiatCurrency?.fiatCurrency ===
                  item?.fiatCurrency
                    ? "bg-[#ccc]"
                    : ""
                } active:bg-[#ccc]`}
              >
                <img
                  className=" w-10 h-10 rounded-full"
                  alt={item?.name}
                  src={item?.coin?.image}
                />
                <div className="text-[#111] text-left ml-3 text-base mr-auto">
                  <h6 className=" text-base ">{item?.fiatName}</h6>
                  <h3 className="text-[10px] opacity-60">{item?.fiatName}</h3>
                </div>
                <div className="text-right text-base ml-auto">
                  <NumericFormat
                    value={item?.coin?.price}
                    decimalScale={4}
                    thousandSeparator
                    displayType="text"
                    className=" text-base"
                    prefix="$"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Currencies;
