import {
  IconArrowNarrowLeft,
  IconArrowsSort,
  IconCreditCard,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Danger, Warning } from "../Core/Errors";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";
import { useSelector } from "react-redux";
import NotradeModal from "./NotradeModal";

const purchage = [
  "One time purchase",
  "Everyday",
  "Everyweek",
  "1th and 15th of every month",
  "Everymonth",
];
function Buy() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [number, setNumber] = useState("");
  const [isInfo, setIsInfo] = useState(false);
  const [isInfo2, setIsInfo2] = useState(false);
  const [warn, setWarn] = useState(false);
  const [active, setActive] = useState(0);
  const [activeCoin, setActiveCoin] = useState("USDT");
  const coin = useLocation().state?.coin;
  const price = useLocation().state?.price;
  const prevData = useLocation().state?.activeCoin;
  // console.log("coin: ", coin);
  useEffect(() => {
    if (price) {
      setNumber(price);
    }
  }, [price]);

  useEffect(() => {
    if (prevData) {
      setActiveCoin(prevData?.activeCoin);
      setNumber(
        prevData?.activeCoin === "USDT"
          ? Number(prevData?.amount)
          : Number(prevData?.qty)
      );
    }
  }, [prevData]);

  const handleClick = (value, index) => {
    if (index === 11) {
      let copyNumber = number;
      copyNumber = copyNumber.substring(0, number.length - 1);
      setNumber(copyNumber);
    } else {
      setNumber((prev) => prev + value);
    }
  };
  return (
    <div>
      <NotradeModal warn={warn} setWarn={setWarn} />
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 pt-12 pb-3 flex flex-col">
        <div className=" flex justify-between items-center">
          <IconArrowNarrowLeft
            className="size-7 cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <h1 className="font-medium leading-[145%] tracking-[-1px] text-[18px] text-[#111111] ">
            {/* Buy  */}
            {getLocale("buy")}
            <span>{coin?.symbol}</span>{" "}
          </h1>
          <p></p>
        </div>
        <div className="mt-5 ">
          <div className=" flex justify-center items-center pl-[65px] ">
            <div className=" flex justify-center items-center">
              <div className="flex flex-col justify-center items-center">
                <h1 className="font-medium leading-[110%] tracking-[-1px] text-[56px] text-[#183056] ">
                  {activeCoin}
                </h1>
                <input
                  onChange={(e) => {
                    setNumber(e.target.value > 0 ? e.target.value : 0);
                  }}
                  className=" outline-none border-none font-medium leading-[110%] w-[100%]  text-center tracking-[-1px] text-[56px] text-[#183056] "
                  type="number"
                  value={number}
                  placeholder="0"
                  name=""
                  id=""
                />
              </div>
              <div>
                <div className=" w-10 h-10 rounded-full border border-[#CFCFCF] flex justify-center items-center cursor-pointer shadow-sm ">
                  <IconArrowsSort
                    onClick={() => {
                      if (activeCoin === "USDT") {
                        setActiveCoin(coin?.symbol);
                      } else {
                        setActiveCoin("USDT");
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={() => setIsInfo2(true)}
            className="w-[70%] px-4 py-2 bg-white text-[#111111] rounded-lg mt-3 text-base border border-md border-[#CFCFCF] "
          >
            {/* One time purchase */}
            {getLocale("oneTime")}
          </button>
        </div>

        <div className=" mt-5">
          <div className="rounded-md border border-[#CFCFCF] bg-opacity-10 w-[98%] px-4 cursor-pointer mt-2 flex items-center justify-center h-[74px] mx-auto active:bg-[#ccc]">
            <IconCreditCard color="#6DA8FF" />
            <div className="text-[#111] text-left ml-3 text-base mr-auto">
              <h3 className="text-base">DBS HSBC Ltd</h3>
              {/* <h6 className="text-sm opacity-60">***8604</h6> */}
            </div>
            <div className="text-right text-base ml-auto">
              <h3 className="text-base">
                {activeCoin === "USDT"
                  ? number / coin?.price
                  : number * coin?.price}
              </h3>
            </div>
          </div>
        </div>

        <div className=" mt-3">
          <div className="bg-white w-full ">
            <div className="grid grid-cols-3 gap-2">
              {[
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                ".",
                "0",
                <IconArrowNarrowLeft />,
              ].map((key, index) => (
                <button
                  key={key}
                  onClick={() => handleClick(key, index)}
                  className=" text-black font-medium text-[28px] py-4 px-6 rounded-lg flex justify-center items-center active:bg-[#ccc] "
                >
                  {key}
                </button>
              ))}
            </div>
          </div>
        </div>

        <div>
          <button
            onClick={() => {
              if (user?.kycStatus !== "APPROVED") {
                // Danger(getLocale("kycMsg"));
                setWarn(true);
              } else {
                if (Number(number) > 0) {
                  setIsInfo(true);
                } else {
                  Warning("Entered amount should be more than zero ");
                }
              }
            }}
            className="w-full p-4 bg-bgBlue text-white rounded-lg mt-2 text-base"
          >
            {/* Visualize purchase */}
            {getLocale("visualize")}
          </button>
        </div>

        <div
          onClick={(e) => {
            e.stopPropagation();
            setIsInfo(!isInfo);
          }}
          className={`${
            isInfo ? "h-full" : "h-0"
          } transition-all w-full bg-[#111111]/[0.2] absolute left-0 bottom-0  flex justify-end flex-col overflow-hidden`}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className=" px-4 py-8 bg-white bottom-0 left-0 rounded-tl-[20px] rounded-tr-[20px] flex flex-col justify-between"
          >
            <div>
              <h1 className="text-[22px] font-medium text-[#111111] leading-[145%] tracking-[-1px] text-left">
                {/* Pay with Stripe */}
                {getLocale("payWithStripe")}
              </h1>
            </div>
            <div
              // onClick={() => {
              //   if (Number(number) > 0) {
              //     navigate("/orderSummary", {
              //       state: { coin: coin, price: number },
              //     });
              //   } else {
              //     Warning("Entered amount should be more than zero ");
              //   }
              // }}
              className="rounded-md w-[98%] px-4 cursor-pointer mt-2 flex items-center justify-center h-[74px] mx-auto bg-[#F5F8FE]"
            >
              <IconCreditCard color="#6DA8FF" />
              <div className="text-[#111] text-left ml-3 text-base mr-auto">
                <h3 className="text-base text-[#2752E7] ">{coin?.symbol}</h3>
                {/* <h6 className="text-sm opacity-60 text-[#2752E7] ">***8604</h6> */}
              </div>
              <div className="text-right text-base ml-auto">
                <h3 className="text-base">
                  {" "}
                  {activeCoin === "USDT"
                    ? number / coin?.price
                    : number * coin?.price}
                </h3>
              </div>
            </div>
            <button
              onClick={() =>
                navigate(ROUTES.ORDER_SUMMARY, {
                  state: {
                    activeCoin: {
                      coin: coin,
                      qty:
                        activeCoin === "USDT" ? number / coin?.price : number,
                      amount:
                        activeCoin === "USDT" ? number : number * coin?.price,
                      activeCoin: activeCoin,
                      order:
                        activeCoin === "USDT"
                          ? number / coin?.price
                          : number * coin?.price,
                    },
                  },
                })
              }
              className="w-full p-4 bg-white text-[#111111] rounded-lg mt-8 text-base border border-md border-[#CFCFCF]"
            >
              {/* Confirm Order */}
              {getLocale("confirm")}
              {getLocale("order")}
            </button>
          </div>
        </div>

        <div
          onClick={(e) => {
            setIsInfo2(!isInfo2);
          }}
          className={`${
            isInfo2 ? "h-full" : "h-0"
          } transition-all w-full bg-[#111111]/[0.2] absolute left-0 bottom-0  flex justify-end flex-col overflow-hidden`}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className=" px-4 py-8 bg-white bottom-0 left-0 rounded-tl-[20px] rounded-tr-[20px] flex flex-col justify-between"
          >
            <div>
              <h1 className="text-[22px] font-medium text-[#111111] leading-[145%] tracking-[-1px] text-left">
                {/* Repeat this purchase? */}
                {getLocale("repeat")}
              </h1>
            </div>
            {purchage?.map((purch, index) => (
              <div
                key={`purch-${index}`}
                onClick={() => setActive(index)}
                className={`text-left rounded-md  w-[98%] px-4 cursor-pointer mt-2 flex items-center justify-start h-[74px] mx-auto ${
                  active == index ? "bg-[#F5F8FE]" : "bg-white"
                }`}
              >
                {purch}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Buy;
