import React, { useEffect, useState } from "react";
import logo from "../../Assets/image/logo 1.png";
import circleLogo from "../../Assets/image/Ellipse.png";
import { Drawer } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { getTradingPair, getUserProfile, getWallet } from "../../service/api";
import { Danger } from "../Core/Errors";
import { ROUTES } from "../../constants/routes";
import actions from "../../redux/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { getLocale } from "../../constants/language";
import { Modal, Box, Typography } from "@mui/material";
import KYCAlert from "../Core/KYCAlert";
import ani from "../../Assets/image/A3.mp4";
import NotradeModal from "./NotradeModal";
import {
  IconAppWindowFilled,
  IconTransformFilled,
  IconX,
} from "@tabler/icons-react";
import deposit from "../../Assets/icons/deposit.svg";
import cart from "../../Assets/icons/Cart.svg";
import { PopUp } from "../Layout/Navbar";

const Landing = ({ isDrawerOpen, sidebarContent, toggleDrawer }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [warn, setWarn] = useState(false);
  const { search } = useLocation();
  const { user, token, liteRoute, P2PLiteorderPayload } = useSelector(
    (state) => state.user
  );
  const [liteOpen, setLiteOpen] = useState(false);
  useEffect(() => {
    if (search === "?kyc") setOpen(true);
  }, [search]);

  const getAssets = () => {
    try {
      setLoader(true);
      getWallet({ walletType: "SPOT" })
        .then((res) => {
          if (res?.status) {
            // setData(res.data[0]?.data);
            // setCount(res.data[0]?.total[0]);
            let total =
              res?.data && res?.data?.length > 0
                ? res?.data[0]?.data &&
                  res?.data[0]?.data?.length > 0 &&
                  res?.data[0]?.data?.reduce(
                    (acc, crr) => acc + +crr.amount * +crr?.coin?.price,
                    0
                  )
                : 0;
            setTotal(total || 0);
          }
        })
        .catch((error) => {
          Danger(error?.response?.data?.message);
        });
    } catch (error) {
      // Danger(error?.response?.data?.message);
      setLoader(false);
    }
  };

  const getPair = () => {
    try {
      setLoader(true);
      getTradingPair({ pagination: false }).then((res) => {
        if (res?.status) {
          setData(res?.data?.result);
        }
      });
    } catch (error) {
      Danger(error?.response?.data?.message);
      setLoader(false);
    }
  };
  const getProfile = () => {
    try {
      getUserProfile()
        .then((res) => {
          if (res?.status) {
            dispatch(actions.setUser(res?.data));
          }
        })
        .catch((error) => {});
    } catch (error) {}
  };
  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    getAssets();
    getPair();
  }, []);

  useEffect(() => {
    if (user?.kycStatus === "IN-PROCESS" && user?.kycLevel === 4) {
      setWarn(true);
    }
  }, [user]);

  const TokenCard = ({ obj }) => {
    const change = obj?.lastPrice
      ? ((+obj?.currentPrice - +obj?.lastPrice) / +obj?.lastPrice) * 100
      : 0;
    return (
      <div className="rounded-lg overflow-x-hidden min-w-[148px] min-h-[135px] p-4 border border-[#cfcfcf] mt-2">
        <div className="flex justify-start relative items-center gap-2">
          <img
            alt=""
            src={obj?.fromCoin?.image}
            className="size-8 absolute left-0 top-0 rounded-full border-2 border-white"
          />
          <img
            alt=""
            src={obj?.toCoin?.image}
            className="size-8 absolute left-5 top-0 rounded-full border-2 border-white"
          />
        </div>
        <div className="text-[#111] flex flex-col items-start mt-12 justify-start text-left text-base mr-auto">
          <h3 className="text-base">
            {obj?.fromCoin?.symbol}/{obj?.toCoin?.symbol}
          </h3>
          <h3 className="text-sm opacity-60">${obj?.currentPrice}</h3>
        </div>
        <h6
          className={`text-[28px] font-medium ${
            change > 0
              ? "text-green-500"
              : change < 0
              ? "text-red-500"
              : "text-[#111]"
          } `}
        >
          {change?.toFixed(2)}%
        </h6>
      </div>
    );
  };

  return (
    <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-6">
      {!open &&
        (user?.kycStatus === "REJECTED" ||
          (user?.kycStatus === "IN-PROCESS" && user?.kycLevel !== 4) ||
          user?.kycStatus === "PENDING") && (
          <div className="mb-10">
            <KYCAlert />
          </div>
        )}
      <div className="relative w-[270px] h-[174px] m-auto mt-5 hidden">
        <div className="w-3/5 h-full rounded-3xl bg-[#183056] absolute bottom-6 left-[50%] -translate-x-[50%] mx-auto"></div>
        <div className="w-4/5 h-full rounded-3xl bg-[#6da8ff] absolute bottom-3 left-[50%] -translate-x-[50%] mx-auto"></div>
        <div className="w-[270px] h-[180px] rounded-2xl bg-gradient-to-b from-[#2865AB] to-[#183056] relative z-10 text-white p-4">
          <div className="text-right w-fit ml-auto">
            <img src={logo} alt="" />
          </div>
          <div className="text-left w-fit absolute bottom-4">
            <p className="text-lg font-medium">5897 1235 3165 8913</p>
            <div className="flex justify-between text-xs mt-1">
              <span>KMBIO ID</span>
              <span>567280107</span>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10 text-[#111111]">
        <h3 className="text-2xl font-medium">
          {/* Bienvenido a KMBIO */}
          {getLocale("welcome")}
        </h3>
        <h6 className="text-base opacity-60 mt-3">
          {/* Haga su primera compra de cripto ahora */}
          {getLocale("welcomeInfo")}
        </h6>
        <button
          onClick={() => navigate(ROUTES.SELECT_ASSETS_TO_BUY)}
          className="w-full h-[58px] p-4 bg-bgBlue text-white rounded-lg mt-10 text-base"
        >
          {/* Comprar criptomonedas */}
          {getLocale("buyCryptocurrency")}
        </button>
      </div>
      <div className="mt-10 text-left text-[#111111]">
        <h3 className="text-xl font-medium">
          {/* Mis monedas */}
          {getLocale("myCoin")}
        </h3>
        <div
          onClick={() => navigate(ROUTES.WALLET)}
          className="rounded-lg bg-[#6da8ff] cursor-pointer bg-opacity-10 w-[98%] px-4 border border-[#cfcfcf] mt-2 flex items-center justify-center h-[74px] mx-auto"
        >
          <img alt="" src={circleLogo} />
          <div className="text-[#111] text-left ml-3 text-base mr-auto">
            <h3 className="text-base">{user?.firstName}</h3>
            {/* <h6 className="text-sm opacity-60">KMB</h6> */}
          </div>
          <div className="text-right text-base ml-auto">
            <h3 className="text-base">{total?.toFixed(4)}</h3>
            {/* <h6 className="text-sm text-[#3f845f]">+3.88%</h6> */}
          </div>
        </div>
      </div>
      <div className="mt-10 text-left text-[#111111]">
        <h3 className="text-xl font-medium">
          {/* Con mayor ganancia */}
          {getLocale("highProffit")}
        </h3>
        <div className="flex items-center justify-start gap-4 overflow-x-scroll no-scrollbar overflow-y-hidden scrollbar-hidden">
          {data &&
            data?.length > 0 &&
            data?.map((obj, index) => {
              return (
                <div
                  className="cursor-pointer"
                  key={index}
                  onClick={() => {
                    dispatch(actions.setActivePair(obj));
                    navigate(ROUTES.CRYPTO_INFO);
                  }}
                >
                  <TokenCard obj={obj} key={index} />
                </div>
              );
            })}
        </div>
      </div>
      <NotradeModal warn={warn} setWarn={setWarn} />

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            width: 250,
            backgroundColor: "#fff",
            right: 0,
          },
          position: "fixed",
          right: 0,
        }}
      >
        {sidebarContent}
      </Drawer>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#fefefe",
            boxShadow: 24,
            borderRadius: 5,
            p: 3,
            textAlign: "center",
            outline: "none",
          }}
        >
          <video className="mx-auto" autoPlay muted playsInline>
            <source src={ani} type="video/mp4" />
          </video>

          <Typography
            id="modal-title"
            variant="h6"
            sx={{ mt: 2 }}
            fontWeight="bold"
          >
            {getLocale("what")}
          </Typography>

          {/* KYC Status Handling */}
          {user?.kycStatus === "PENDING" || user?.kycStatus === "IN-PROCESS" ? (
            <>
              <Typography
                id="modal-description"
                variant="body2"
                sx={{ mt: 1, textAlign: "left" }}
              >
                <label className="font-[700] text-left">
                  {getLocale("importantReminderBold")}
                </label>
              </Typography>
              <Typography variant="body2" sx={{ mt: 1, textAlign: "left" }}>
                1. {getLocale("kycReviewTimeText")} {getLocale("12h")}
              </Typography>
              {/* <Typography variant="body2" sx={{ mt: 1,textAlign:"left" }}>
      <label className="font-[700] text-left">
        2.{getLocale("whatsNextBold")}
      </label>
    </Typography> */}
              {/* <Typography variant="body2" sx={{ mt: 1,textAlign:"left" }}>
      {getLocale("whatsNextText")}
    </Typography> */}
              {/* <Typography variant="body2" sx={{ mt: 1,textAlign:"left" }}>
      <label className="font-[700] text-left">
        3.{getLocale("importantReminderBold")}
      </label>
    </Typography> */}
              <Typography variant="body2" sx={{ mt: 1, textAlign: "left" }}>
                2. {getLocale("importantReminderText")}
              </Typography>

              <ul className="flex flex-col gap-1 justify-start ml-5 mt-1">
                <li className="font-semibold min-w-[150px] text-xs list-disc text-left marker:text-gray-400">
                  {getLocale("idTypeDrivingLicense")}
                </li>
                <li className="font-semibold list-disc min-w-[150px] text-xs text-left marker:text-gray-400">
                  {getLocale("idTypeIDCard")}
                </li>
                <li className="font-semibold list-disc min-w-[150px] text-xs text-left marker:text-gray-400">
                  {getLocale("idTypePassport")}
                </li>
                <li className="font-semibold list-disc min-w-[150px] text-xs text-left marker:text-gray-400">
                  {getLocale("idTypeVisa")}
                </li>
                <li className="font-semibold list-disc min-w-[150px] text-xs text-left marker:text-gray-400">
                  {getLocale("idTypeVoterID")}
                </li>
              </ul>
            </>
          ) : user?.kycStatus === "APPROVED" ? (
            <>
              <Typography variant="body" sx={{ mt: 2 }}>
                <label className="font-[700] text-left">
                  {getLocale("kycCompletedNotApprovedBold")}
                </label>
              </Typography>
              <Typography variant="body" sx={{ mt: 2 }}>
                {getLocale("kycCompletedNotApprovedText")}
              </Typography>
            </>
          ) : user?.kycStatus === "REJECTED" ? (
            <>
              <Typography
                id="modal-description"
                variant="body2"
                sx={{ mt: 1, mb: 2 }}
              >
                <label className="font-[700] text-left">
                  {getLocale("kycRejectedBold")}
                </label>
              </Typography>
              <Typography variant="body2" sx={{ mt: 2 }}>
                {getLocale("kycRejectedText")}
              </Typography>
              <Typography variant="body2" sx={{ mt: 2 }}>
                {getLocale("kycRejectedSuggestion")}
              </Typography>
            </>
          ) : null}

          <div className="flex justify-center items-center w-full gap-2 mt-5">
            <button
              onClick={() => {
                setOpen(false);
                navigate(ROUTES.SECURITY_ID);
              }}
              className="w-full p-3 bg-bgBlue text-white rounded-full text-base font-[600]"
            >
              {getLocale("verifyNowbtn")}
            </button>
            <button
              onClick={() => {
                setOpen(false);
              }}
              className="w-full p-3 bg-bgBlue text-white rounded-full text-base font-[600]"
            >
              {getLocale("later")}
            </button>
          </div>
        </Box>
      </Modal>
      <PopUp open={false} setOpen={setLiteOpen}>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className=" space-y-4 bg-bgWhite rounded-tl-xl py-4 rounded-tr-xl max-w-[425px] mx-auto w-full"
        >
          <div
            onClick={() => {
              navigate(ROUTES.LITEBUY);
              dispatch(
                actions.setP2PLitePaylaod({
                  ...P2PLiteorderPayload,
                  type: "BUY",
                })
              );
              dispatch(actions.openCloseLiteRoute(false));
            }}
            className=" px-4 flex cursor-pointer  text-[#111111] hover:bg-[#ccc] py-2  font-semibold"
          >
            {" "}
            {/* Buy  */}
            <div className=" flex items-center justify-start gap-2">
              <img src={cart} alt="" className=" size-5 " />{" "}
              <div>
                <p className="text-[14px] text-left">{getLocale("buy")}</p>
                <p className=" text-[11px] font-normal text-left  opacity-80 ">
                  Buy crypto with INR
                </p>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              navigate(ROUTES.LITEBUY);
              dispatch(
                actions.setP2PLitePaylaod({
                  ...P2PLiteorderPayload,
                  type: "SELL",
                })
              );
              dispatch(actions.openCloseLiteRoute(false));
            }}
            className=" px-4 flex cursor-pointer  text-[#111111] hover:bg-[#ccc] py-2  font-semibold"
          >
            {" "}
            {/* Buy  */}
            <div className=" flex items-center justify-start gap-2">
              <img src={deposit} alt="" />{" "}
              <div>
                <p className="text-[14px] text-left">{getLocale("sell")}</p>
                <p className=" text-[11px] font-normal text-left  opacity-80 ">
                  Sell crypto with INR
                </p>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              navigate(ROUTES.CONVERT);
              dispatch(actions.openCloseLiteRoute(false));
            }}
            className=" px-4 flex  text-[#111111] hover:bg-[#ccc] py-2  font-semibold"
          >
            {" "}
            {/* Buy  */}
            <div className=" flex items-center justify-start gap-2">
              <IconTransformFilled size={20} />
              <div>
                <p className="text-[14px] text-left">{getLocale("Convert")}</p>
                <p className=" text-[11px] font-normal text-left  opacity-80 ">
                  Trade with convert instantly
                </p>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              navigate(ROUTES.DEPOSIT);
              dispatch(actions.openCloseLiteRoute(false));
            }}
            className=" px-4 flex  text-[#111111] hover:bg-[#ccc] py-2  font-semibold"
          >
            {" "}
            <div className=" flex items-center justify-start gap-2">
              <IconAppWindowFilled size={20} />
              <div>
                <p className="text-[14px] text-left">{getLocale("deposit")}</p>
                <p className=" text-[11px] font-normal text-left  opacity-80 ">
                  Deposit with fiat and crypto currency
                </p>
              </div>
            </div>
          </div>
          <div className=" flex  justify-center items-center px-2.5 pt-2.5 ">
            <div className=" cursor-pointer px-2 py-2 rounded-full bg-bgBlue text-textWhite shadow-2xl active:scale-[1.02] transition-all active:bg-bgWhite  border border-black active:text-textPrime ">
              <IconX
                onClick={() => dispatch(actions.openCloseLiteRoute(false))}
                size={25}
                className=" cursor-pointer text-inherit "
              />
            </div>
          </div>
        </div>
      </PopUp>
    </div>
  );
};

export default Landing;
