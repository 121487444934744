import {
  IconArrowNarrowLeft,
  IconBrandVisa,
  IconChevronRight,
  IconInfoCircle,
  IconTransfer,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/user/actions";
import { NumericFormat } from "react-number-format";
import {
  getAllP2pOrders,
  getAllPayMethodByUser,
  getAllPaymethods,
  placeP2POrder,
} from "../../../service/api";
import { ROUTES } from "../../../constants/routes";
import { getLocale } from "../../../constants/language";

function SelectPaymethod() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { P2PLiteorderPayload } = useSelector((state) => state.user);
  console.log(
    "%c Line:23 🍡 P2PLiteorderPayload",
    "color:#3f7cff",
    P2PLiteorderPayload
  );
  const [open, setOpen] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const [payMethod, setPayMethod] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [currOrder, setCurrOrder] = useState();
  const [switchTab, setSwitchTab] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    filterOrder(switchTab);
  }, [switchTab]);

  const filterOrder = (id) => {
    let data =
      orderData &&
      orderData?.length > 0 &&
      orderData?.find((_, index) => index === id);
    setCurrOrder(data);
  };

  const getPaymentMethods = (id) => {
    let params = { currency: id };
    getAllPaymethods(1, false, 10, params)?.then((res) => {
      if (res?.status) {
        setPayMethod(res?.data?.result);
      }
    });
  };
  const getPaymentMethodsUser = () => {
    getAllPayMethodByUser(1, false, 10)?.then((res) => {
      if (res?.status) {
        console.log("%c Line:84 🎂 res", "color:#e41a6a", res);
        setPayMethod(res?.data?.result);
      }
    });
  };

  useEffect(() => {
    if (
      P2PLiteorderPayload?.fiatCurrency?.fiatCurrency &&
      P2PLiteorderPayload?.type === "BUY"
    ) {
      getPaymentMethods(P2PLiteorderPayload?.fiatCurrency?.fiatCurrency);
    } else {
      getPaymentMethodsUser();
    }
  }, [P2PLiteorderPayload]);

  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelection = (id) => {
    console.log("%c Line:103 🍅 id", "color:#3f7cff", id);
    dispatch(
      actions.setP2PLitePaylaod({
        ...P2PLiteorderPayload,
        paymentMethods:
          P2PLiteorderPayload?.type === "BUY" ? id?._id : id?.paymentMethodId,
      })
    );
  };

  const getOrders = () => {
    let body = {
      amount: P2PLiteorderPayload?.byWhich
        ? +P2PLiteorderPayload?.price
        : +P2PLiteorderPayload?.price * +P2PLiteorderPayload?.coin?.coin?.price,
      type: P2PLiteorderPayload?.type === "BUY" ? "SELL" : "BUY",
      fiatCurrency: P2PLiteorderPayload?.fiatCurrency?.fiatCurrency,
      paymentMethods: [P2PLiteorderPayload?.paymentMethods],
      coin: P2PLiteorderPayload?.coin?.coin?._id,
    };
    getAllP2pOrders(1, false, 10, body)?.then((res) => {
      if (res?.status) {
        setOrderData(res?.data?.result);
        setSwitchTab(res?.data?.result?.length > 0 ? 0 : null);
      }
    });
  };

  useEffect(() => {
    return () => {
      actions.setP2PLitePaylaod({
        ...P2PLiteorderPayload,
        paymentMethods: null,
      });
    };
  }, [pathname]);

  const handlePayment = () => {
    let body = {
      offerId: currOrder?._id,
      type: P2PLiteorderPayload?.type,
      paymentMethods: P2PLiteorderPayload?.paymentMethods,
      paymentTimeLimitMinutes: currOrder?.paymentTimeLimitMinutes,
      totalPaymentInFait: P2PLiteorderPayload?.byWhich
        ? +P2PLiteorderPayload?.price
        : +P2PLiteorderPayload?.price,
      totalAmountCoin: P2PLiteorderPayload?.byWhich
        ? +P2PLiteorderPayload?.price / +currOrder?.amount
        : +currOrder?.amount,
    };
    setOrderLoading(true);
    placeP2POrder(body)
      ?.then((res) => {
        if (res?.status) {
          navigate(ROUTES.VERIFYORDER, {
            state: {
              response: res?.data,
              ID: currOrder?._id,
              fields: currOrder,
            },
          });
        }
      })
      ?.finally(() => setOrderLoading(false));
  };

  useEffect(() => {
    const handleUnload = () => {
      actions.setP2PLitePaylaod({
        ...P2PLiteorderPayload,
        paymentMethods: null,
      });
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      handleUnload();
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [P2PLiteorderPayload]);

  // useEffect(() => {
  //   getOrders();
  // }, []);

  return (
    <>
      <div
        className={`h-full min-h-[100vh] max-h-[100vh] overflow-y-auto relative justify-start bg-white font-sans pt-8 pb-3 flex flex-col`}
      >
        <div className=" px-4 flex justify-between items-center">
          <IconArrowNarrowLeft
            className="size-7 cursor-pointer"
            onClick={() => {
              actions.setP2PLitePaylaod({
                ...P2PLiteorderPayload,
                paymentMethods: null,
              });
              navigate(-1);
            }}
          />
          <h1 className="font-semibold leading-[145%] tracking-[-1px] text-[18px] text-[#111111] ">
            {getLocale("selectMethod")}
          </h1>
          <p className=" size-10 rounded-full hover:bg-[#ccc] transition-all active:bg-bg-[#ccc] flex items-center justify-center "></p>
        </div>
        <div className=" px-4">
          <div className=" mt-4 px-4">
            <p className=" text-[14px] text-textPrime opacity-80 ">
              {getLocale("get")}
            </p>
            <h2 className=" flex gap-1 items-center justify-center w-full">
              <NumericFormat
                className="text-[22px] text-textPrime opacity-100"
                value={
                  P2PLiteorderPayload?.byWhich
                    ? Number(P2PLiteorderPayload?.price) /
                      Number(P2PLiteorderPayload?.coin?.coin?.price)
                    : +P2PLiteorderPayload?.price
                }
                displayType="text"
                decimalScale={4}
              />
              <span className="text-[22px] text-textPrime opacity-100">
                {P2PLiteorderPayload?.coin?.coin?.symbol}
              </span>
            </h2>
          </div>
          <div className=" px-4 py-2 border rounded-md mt-8 ">
            <p className=" flex items-center gap-1">
              {getLocale("p2p")} <IconInfoCircle size={20} />
            </p>
            <div className="flex w-full justify-start items-center gap-3 flex-wrap">
              {" "}
              {payMethod && payMethod?.length > 0 ? (
                payMethod?.map((method) => (
                  <div
                    key={method?._id}
                    className={`flex mt-4 justify-between items-center rounded-lg mb-3 cursor-pointer ${
                      P2PLiteorderPayload?.paymentMethods ===
                      (P2PLiteorderPayload?.type === "BUY"
                        ? method?._id
                        : method?.paymentMethodId)
                        ? "bg-white"
                        : "bg-white"
                    }`}
                    onClick={() => handleSelection(method)}
                  >
                    <div
                      className="relative flex items-center justify-center rounded-lg border border-black p-2 cursor-pointer min-w-[90px] min-h-[90px]"
                      onChange={() => handleSelection(method)}
                    >
                      <div className="font-semibold text-left">
                        {method?.name}
                      </div>
                      <div className="text-sm text-gray-500 text-left mt-3">
                        {method?.type}
                      </div>
                      <div className="absolute right-1 top-1 flex items-center gap-2 justify-between">
                        <div className="hidden font-semibold">{`${
                          P2PLiteorderPayload?.byWhich
                            ? P2PLiteorderPayload?.price
                            : P2PLiteorderPayload?.price *
                              +P2PLiteorderPayload?.coin?.coin?.price
                        } ${P2PLiteorderPayload?.fiatCurrency?.fiatName}`}</div>
                        <input
                          type="radio"
                          className="appearance-none w-5 h-5 border-2 border-gray-300 rounded-full checked:before:h-[7px] checked:before:w-[7px] checked:before:absolute checked:before:bg-white checked:before:rounded-full checked:before:left-[5.2px] checked:before:top-[5px] relative checked:before:z-40 checked:bg-black checked:border-white focus:outline-none"
                          checked={
                            (P2PLiteorderPayload?.type === "BUY"
                              ? method?._id
                              : method?.paymentMethodId) ===
                            P2PLiteorderPayload?.paymentMethods
                          }
                          onChange={() => handleSelection(method)}
                        />
                      </div>
                    </div>

                    <div className="hidden">
                      <div className="font-semibold text-left">
                        {method?.name}
                      </div>
                      <div className="text-sm text-gray-500 text-left">
                        {method?.type}
                      </div>
                    </div>
                    <div className=" hidden items-center gap-2 justify-between">
                      <div className="hidden font-semibold">{`${
                        P2PLiteorderPayload?.byWhich
                          ? P2PLiteorderPayload?.price
                          : P2PLiteorderPayload?.price *
                            +P2PLiteorderPayload?.coin?.coin?.price
                      } ${P2PLiteorderPayload?.fiatCurrency?.fiatName}`}</div>
                      <input
                        type="radio"
                        className="appearance-none w-5 h-5 border-2 border-gray-300 rounded-full checked:before:h-[7px] checked:before:w-[7px] checked:before:absolute checked:before:bg-white checked:before:rounded-full checked:before:left-[5.2px] checked:before:top-[5px] relative checked:before:z-40 checked:bg-black checked:border-white focus:outline-none"
                        checked={
                          (P2PLiteorderPayload?.type === "BUY"
                            ? method?._id
                            : method?.paymentMethodId) ===
                          P2PLiteorderPayload?.paymentMethods
                        }
                        onChange={() => handleSelection(method)}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <div
                  className={`flex mt-4 justify-between items-center  rounded-lg mb-3 cursor-pointer bg-white`}
                >
                  <div>
                    <div className="text-sm text-gray-500 text-left">
                      {getLocale("noPay")}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="hidden px-4 py-2 border rounded-md mt-8 ">
            <p className=" flex items-center gap-1">
              Card/Bank Accounts/Others
            </p>

            <div
              className={`flex mt-4 justify-between items-center py-2 rounded-lg mb-3 cursor-pointer bg-white
              `}
              onClick={() => handleSelection("Bank")}
            >
              <div className=" flex items-center gap-2">
                <IconBrandVisa />
                <div>
                  <div className="font-semibold text-left">
                    Card (VISA/Mastercard)
                  </div>
                  <div className="text-sm text-gray-500 text-left flex items-center gap-1">
                    Add <IconChevronRight size={20} />
                  </div>
                </div>
              </div>
              <div className=" flex items-center gap-2 justify-between">
                <div className="font-semibold">{1981.71} INR</div>
                <input
                  type="radio"
                  className="appearance-none w-5 h-5 border-2 border-gray-300 rounded-full checked:before:h-[7px] checked:before:w-[7px] checked:before:absolute checked:before:bg-white checked:before:rounded-full checked:before:left-[5.2px] checked:before:top-[5px] relative checked:before:z-40 checked:bg-black checked:border-white focus:outline-none"
                  checked={P2PLiteorderPayload?.paymentMethods === "Bank"}
                  onChange={() => handleSelection("Bank")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className=" px-4 py-2 border-t mt-4 bg-bgWhite fixed w-full max-w-[425px] bottom-0">
          <div
            className={`flex mt-4 justify-between items-center py-4 rounded-lg mb-3 cursor-pointer bg-white
                `}
          >
            <div className=" items-center gap-2">
              <p className=" flex items-center gap-1 text-left text-sm">
                {getLocale("pay")}
              </p>

              <div>
                <div className="font-semibold">{`${
                  P2PLiteorderPayload?.byWhich
                    ? P2PLiteorderPayload?.price
                    : P2PLiteorderPayload?.price *
                      +P2PLiteorderPayload?.coin?.coin?.price
                } ${P2PLiteorderPayload?.fiatCurrency?.fiatName}`}</div>
                <div className="text-[10px] hidden text-gray-500 text-left flex items-center gap-1">
                  Price valid for 41s
                </div>
              </div>
            </div>
            <div className=" flex items-center gap-2 justify-between">
              <button
                disabled={!P2PLiteorderPayload?.paymentMethods}
                onClick={() => {
                  getOrders();
                  setOpen(true);
                }}
                className={`bg-bgBlue rounded-[20px] ${
                  !P2PLiteorderPayload?.paymentMethods
                    ? "opacity-70 cursor-not-allowed"
                    : "opacity-100 cursor-pointer"
                } text-textWhite text-center px-6 font-semibold py-2`}
              >
                {getLocale("confirm")}
              </button>
            </div>
          </div>
        </div>
        <PopUp open={open} setOpen={setOpen}>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="font-sans w-full max-w-[425px] mx-auto mt-2 px-4 bg-bgWhite rounded-tl-xl rounded-tr-xl py-2"
          >
            {/* Header Section */}
            <h3 className="text-[16px] font-bold mb-4 text-left w-full ">
              {getLocale("preview")}
            </h3>

            {/* Trade Info Section */}
            {currOrder ? (
              <div className="p-4 border rounded-lg mb-4">
                <div className="flex justify-between items-center">
                  <div className=" w-full">
                    <div className=" flex justify-between items-center w-full">
                      <p className="font-semibold  opacity-80 text-left  text-sm">
                        {getLocale("trade")}
                      </p>
                      <button
                        onClick={() => {
                          setSwitchTab(
                            switchTab === orderData?.length - 1
                              ? 0
                              : switchTab + 1
                          );
                          filterOrder(
                            switchTab === orderData?.length - 1
                              ? 0
                              : switchTab + 1
                          );
                        }}
                        disabled={
                          orderLoading || !orderData || orderData?.length === 0
                        }
                        className="text-textPrime font-semibold flex items-center gap-1 px-1.5 py-1 bg-[#ccc] rounded-[20px] text-[16px]"
                      >
                        <IconTransfer size={20} />
                        {getLocale("switch")}
                      </button>
                    </div>
                    <p className="text-lg font-semibold text-left mt-2 ">
                      {currOrder?.sellerId?.firstName}{" "}
                      {currOrder?.sellerId?.lastName}
                    </p>
                    {/* <p className="text-sm text-primBlue text-left mt-2">
                      Trades: 2101 (99.90%)
                    </p> */}
                    <p className="text-[12px] text-gray-500 text-left mt-2">
                      {getLocale("req")}
                    </p>
                  </div>
                </div>
                <div className="mt-4  text-sm font-semibold text-left">
                  {currOrder?.remarks}
                  {/* 🚫🚫 FOLLOWING THIS STEP OTHERWISE CANC... */}
                </div>
              </div>
            ) : (
              <>
                {" "}
                <div className="p-4 border rounded-lg mb-4">
                  <div className="flex justify-between items-center">
                    <div className=" w-full">
                      <p className="text-lg text-center font-semibold mt-2 ">
                        {getLocale("noOrder")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between w-full items-center ">
                  {" "}
                  <a
                    href={`mailto:${"admin@kmbio.com"}`}
                    className="text-left cursor-pointer !text-xs font-medium"
                  >
                    {getLocale("support")}
                  </a>
                  <div
                    onClick={() => {
                      if (P2PLiteorderPayload?.type === "BUY") {
                        navigate(ROUTES.SELECT_ASSETS_TO_BUY);
                      } else {
                        navigate(ROUTES.MARKET);
                      }
                    }}
                    className="text-left cursor-pointer text-xs font-medium"
                  >
                    {getLocale("inBuy")}
                  </div>
                </div>
              </>
            )}

            {/* Payment Details */}
            {currOrder && (
              <div className="p-4 border rounded-lg mb-4 space-y-2">
                <div className="flex justify-between">
                  <p className="text-gray-500">You Pay</p>
                  <p className="font-semibold">{`${
                    P2PLiteorderPayload?.fiatCurrency?.fiatName
                  } ${
                    P2PLiteorderPayload?.byWhich
                      ? P2PLiteorderPayload?.price
                      : P2PLiteorderPayload?.price *
                        +P2PLiteorderPayload?.coin?.coin?.price
                  }`}</p>
                </div>
                <div className="flex justify-between">
                  <p className="text-gray-500">You Receive</p>
                  <p className="font-semibold">
                    {" "}
                    {`${
                      P2PLiteorderPayload?.byWhich
                        ? (
                            Number(P2PLiteorderPayload?.price) /
                            Number(currOrder?.price)
                          )?.toFixed(4)
                        : // Number(+currOrder?.amount) *
                          Number(+P2PLiteorderPayload?.price)?.toFixed(4)
                    } ${P2PLiteorderPayload?.coin?.coin?.symbol}`}
                  </p>
                </div>
                <div className="flex justify-between">
                  <p className="text-gray-500">Pay with</p>
                  <p className="font-semibold">
                    UPI{" "}
                    <span className="text-gray-500 border-b">
                      {currOrder?.paymentTimeLimitMinutes} min
                    </span>
                  </p>
                </div>
              </div>
            )}
            {/* Buttons Section */}
            <div className="flex justify-between mt-4 gap-4">
              <button
                onClick={() => setOpen(false)}
                className="w-1/3 bg-gray-200 text-gray-700 py-2 px-4 rounded-lg font-semibold hover:scale-[1.02] transition-all"
              >
                {getLocale("backBtn")}
              </button>
              <button
                disabled={orderLoading || !currOrder}
                onClick={() => handlePayment()}
                className={`w-2/3 bg-bgBlue ${
                  orderLoading || !currOrder ? "opacity-60" : "opacity-100"
                } text-white py-2 px-4 rounded-lg font-semibold hover:scale-[1.02] transition-all`}
              >
                {getLocale("placeOrder")}
              </button>
            </div>
          </div>
        </PopUp>
      </div>
    </>
  );
}

export default SelectPaymethod;

function PopUp({ children, open, setOpen }) {
  return (
    <div
      onClick={(e) => {
        setOpen(false);
      }}
      className={` z-30 ${
        open ? "h-[100%]" : "h-0"
      } transition-all  flex flex-col justify-end border-t top-0 overflow-hidden absolute mb-[-15px] w-full bg-bgBlue/[0.3] left-0 shadow-2xl `}
    >
      {children}
    </div>
  );
}
