import swal from "sweetalert";
import { getLocale } from "../../constants/language";
export const Danger = (error) => {
  return swal({
    title: getLocale("error"),
    text: error,
    icon: "error",
    dangerMode: true,
    className: "custom-swal-modal",
    buttons: {
      cancel: {
        text: getLocale("close"),
        value: true,
        visible: true,
        closeModal: true,
      },
    },
  });
};
export const Warning = (error) => {
  return swal({
    title: getLocale("warning"),
    text: error,
    icon: "warning",
    dangerMode: true,
    className: "custom-swal-modal",
    buttons: {
      cancel: {
        text: getLocale("close"),
        value: true,
        visible: true,
        closeModal: true,
      },
    },
  });
};
export const Confrim = (error) => {
  return swal({
    // className: "CustomeBody",
    title: getLocale("warning"),
    text: error ? error : "Are you sure you want to delete？",
    icon: "warning",
    dangerMode: true,
    className: "custom-swal-modal",
    buttons: {
      catch: {
        text: getLocale("yes"),
        value: "catch",
        visible: true,
        // className: "btnCustom",
        closeModal: true,
      },
      close: {
        text: "No",
        value: "cancel",
        visible: true,
        // className: "btnCustom",
        closeModal: true,
      },
    },
  });
};
export const Success = (error) => {
  const name =
    error === "KYC Process Initiated Successfully" ||
    error === "KYC Verification Submitted for Review";
  return swal({
    title: getLocale("success"),
    text: error,
    icon: "success",
    dangerMode: true,
    className: "custom-swal-modal",
    buttons: {
      cancel: {
        text: name ? getLocale("continue") : getLocale("close"),
        value: true,
        visible: true,
        closeModal: true,
      },
    },
  });
};
