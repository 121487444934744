import { TextField } from "@mui/material";
import { getLocale } from "../../constants/language";

const CustomInput = (props) => {
  return (
    <TextField
      ref={props.ref}
      type={props.type}
      name={props.name}
      id={props.id}
      error={props.error}
      variant="outlined"
      label={props.error ? getLocale("required") : props.placeholder}
      placeholder={props.type !== "date" ? props.placeholder : "Date of birth"}
      defaultValue={props?.defaultValue}
      value={props.value}
      disabled={props?.disabled}
      onChange={props.onChange}
      onClick={props.onClick}
      readOnly={props.readOnly}
      autoComplete={props?.autoComplete}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      pattern={props.pattern}
      InputLabelProps={{ shrink: true }}
      className={`${
        props.disabled ? "bg-bodyBackground bg-opacity-70" : "bg-transparent"
      } w-full h-full block border-2 ${
        props?.error ? "border-red-500" : "border-[#E5E5E5]"
      } !rounded-xl text-base font-proxima-regular px-5 py-3 placeholder:font-proxima-regular placeholder:text-base focus:outline-none text-black outline-none`}
    />
  );
};

export { CustomInput };

// const CustomInput = (props) => {
//   return (
//     <TextField
//       ref={props.ref}
//       type={props.type}
//       name={props.name}
//       id={props.id}
//       variant="outlined"
//       label={props.placeholder}
//       placeholder={props.placeholder}
//       defaultValue={props?.defaultValue}
//       value={props.value}
//       disabled={props?.disabled}
//       onChange={props.onChange}
//       onClick={props.onClick}
//       readOnly={props.readOnly}
//       autoComplete={props?.autoComplete}
//       onFocus={props.onFocus}
//       onBlur={props.onBlur}
//       pattern={props.pattern}
//       className={`${
//         props.disabled ? "bg-bodyBackground bg-opacity-70" : "bg-transparent"
//       } w-full h-full block border-2 ${
//         props?.error ? "border-red-500" : "border-[#E5E5E5]"
//       } rounded-md text-base font-proxima-regular px-5 py-3 placeholder:font-proxima-regular placeholder:text-base focus:outline-none text-black outline-none`}
//     />
//   );
// };
// export { CustomInput };
