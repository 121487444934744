const { default: store } = require("../redux/store");

const getServerUrl = () => {
  const language = store.getState().user?.language || "en";
  return `https://kmbio-latest-a3ehfzcxc4h9dkau.japanwest-01.azurewebsites.net/${language === "sp" ? "es" : "en"}`;
};

const getPublicLocale = () => {
  return store.getState().user?.language || "en";
};

module.exports = {
  getServerUrl,
  getPublicLocale,
};
