import { IconX } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ATMACRD from "../../Assets/image/ATMCARD.png";
import { addIdProof, getUserProfile } from "../../service/api";
import Loader from "../Core/Loader";
import { ROUTES } from "../../constants/routes";
import { getLocale } from "../../constants/language";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import { countries } from "../Core/Countries";
import { Danger } from "../Core/Errors";

const Ids = [
  {
    title: "ine",
    subTitle: "",
    value: "INE",
  },
  {
    title: "licence",
    subTitle: "Recomendada",
    value: "Licence",
  },
  {
    title: "passport",
    subTitle: "",
    value: "Passport",
  },
];

function SecurityId() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const email = useLocation().state?.email;
  const [selectedCountry, setSelectedCountry] = useState("MX");
  const [verificationId, setVerificationId] = useState("");
  const [verificationIdErr, setVerificationIdErr] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [nationality, setNationality] = useState(countries[155]);

  const getProfile = () => {
    try {
      getUserProfile()
        .then((res) => {
          let nation = countries?.find(
            (obj) => obj?.code === res?.data?.kyc?.nationality
          );
          setSelectedCountry(nation?.code || "MX");
          setNationality(nation);
          setUserInfo({
            nationality: nation?.code || "MX",
            firstName: res?.data?.kyc?.firstName,
            lastName: res?.data?.kyc?.lastName,
          });
        })
        .catch((error) => {});
    } catch (error) {}
  };

  useEffect(() => {
    getProfile();
  }, []);

  const countryOptions = countries.map((country) => ({
    code: country.code,
    name: country.label,
  }));

  const chooseIdType = () => {
    if (!verificationId) {
      setVerificationIdErr(true);
      return;
    }
    try {
      setLoading(true);
      addIdProof({
        documentName: verificationId,
        nationality: userInfo?.nationality || selectedCountry,
      })
        .then((resp) => {
          if (resp.status) {
            navigate(ROUTES.PERSONAL_INFO, { state: { email: email } });
          }
        })
        .catch((error) => {
          Danger(error?.response?.data?.message);
          setLoading(false);
        })
        .finally((res) => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col justify-between">
        <div>
          <div className=" flex justify-between items-center">
            <h2 className=" text-[20px] font-[700] text-left ">
              {getLocale("identity")}
            </h2>
            <IconX
              className="size-6 cursor-pointer transition-transform duration-300 hover:rotate-90"
              onClick={() => navigate(-1)}
            />
          </div>

          <div className="my-2 w-full">
            <label className="block text-left text-textDefault text-base font-[700]">
              {getLocale("countryissue")}
            </label>
            <Select
              labelId="country-select-label"
              id="country-select"
              value={selectedCountry}
              onChange={(e) => {
                setSelectedCountry(e.target.value);
              }}
              className="border border-[#fcfcfc] w-full !rounded-lg  "
              renderValue={(value) => (
                <div className="flex items-center gap-2">
                  <img
                    src={`https://flagcdn.com/w40/${value.toLowerCase()}.png`}
                    alt={`${value} flag`}
                    className="w-5 h-4"
                  />
                  <span>
                    {countryOptions.find((c) => c.code === value)?.name}
                  </span>
                </div>
              )}
            >
              {countryOptions.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  <div className="flex items-center gap-2">
                    <img
                      src={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png`}
                      alt={`${country.name} flag`}
                      className="w-5 h-4"
                    />
                    <span>{country.name}</span>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="mt-5">
            <h2 className=" text-base font-[700] text-left ">
              {/* Select the type of your ID */}
              {getLocale("typeOfId")}
            </h2>
            {/* <p className=" text-left text-[16px] font-normal space-x-[0.2px] mt-3 text-[#707070]">
              We will take 2 photos of your ID. What type of identification
              would you like to use?
              {getLocale("idInfo")}{" "}
            </p> */}
          </div>
          <div className="mt-0">
            {Ids?.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  setVerificationId(item.value);
                  setVerificationIdErr(false);
                }}
                className={` flex justify-between items-center mt-5 cursor-pointer border ${
                  verificationIdErr ? "border-red-500" : "border-[#d0cece]"
                } active:bg-[#ccc] min-h-[50px] px-2 rounded-md`}
              >
                <div className=" flex items-center gap-2">
                  <img src={ATMACRD} alt="CARD" />
                  <div>
                    <p className=" text-[16px] font-normal space-x-[0.2px text-[#111111] ">
                      {getLocale(item?.title)}
                    </p>
                    <p className="text-[#707070] text-[14px] text-left">
                      {item?.subTitle}
                    </p>
                  </div>
                </div>
                <input
                  type="radio"
                  id={`radio-${item?.value}`}
                  value={verificationId}
                  checked={verificationId === item?.value}
                  className="flex flex-col size-4 gap-2 border outline-none peer-checked:bg-black peer-checked:border-black peer-checked:ring-2 peer-checked:ring-black"
                />
              </div>
            ))}
          </div>
          <div>
            <p className=" text-[#707070] text-[14px] text-justify my-3">
              {/* Your photo ID and the actions captured during the identity
            verification process may constitute biometric data. Please refer to
            our Privacy Policy for more information on how we store and use your
            biometric data */}
              {getLocale("idInfoEnd")}
            </p>
          </div>
          {loading ? (
            <button
              disabled={loading}
              className="w-[100px] float-right ml-auto p-2 bg-bgBlue text-white rounded-full mt-5 text-base"
            >
              <CircularProgress size={20} color="white" thickness={6} />
            </button>
          ) : (
            <button
              onClick={chooseIdType}
              disabled={loading}
              className="w-[100px] float-right ml-auto p-2 bg-bgBlue text-white rounded-full mt-5 text-base font-[700]"
            >
              {getLocale("continue")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default SecurityId;

// OLD CODE
// <div>
// {loading && <Loader />}
// <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col justify-between">
//   <div>
//     <div className=" flex justify-between items-center">
//       <IconChevronLeft
//         className="size-7 cursor-pointer"
//         onClick={() => navigate(-1)}
//       />
//       <img src={stepImage} alt="step-top" />
//       <p></p>
//     </div>
//     <div className=" mt-9">
//       <h2 className=" text-[22px] font-[700] text-left ">
//         {/* Select the type of your ID */}
//         {getLocale("typeOfId")}
//       </h2>
//       <p className=" text-left text-[16px] font-normal space-x-[0.2px] mt-3 text-[#707070]">
//         {/* We will take 2 photos of your ID. What type of identification
//         would you like to use? */}
//         {getLocale("idInfo")}{" "}
//       </p>
//     </div>
//     <div className=" mt-6">
//       {Ids?.map((item, index) => (
//         <div
//           onClick={() => chooseIdType(item.value)}
//           className=" flex justify-between items-center mt-5 cursor-pointer active:bg-[#ccc] min-h-[50px] py-1 px-2 rounded-md"
//         >
//           <div className=" flex items-center gap-2">
//             <img src={ATMACRD} alt="CARD" />
//             <div>
//               <p className=" text-[16px] font-normal space-x-[0.2px text-[#111111] ">
//                 {item?.title}
//               </p>
//               <p className="text-[#707070] text-[14px] text-left">
//                 {item?.subTitle}
//               </p>
//             </div>
//           </div>
//           <IconChevronRight className=" cursor-pointer" />
//         </div>
//       ))}
//     </div>
//   </div>
//   <div>
//     <p className=" text-[#707070] text-[14px] text-justify">
//       {/* Your photo ID and the actions captured during the identity
//       verification process may constitute biometric data. Please refer to
//       our Privacy Policy for more information on how we store and use your
//       biometric data */}
//       {getLocale("idInfoEnd")}
//     </p>
//   </div>
// </div>
// </div>
