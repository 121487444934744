import { IconArrowNarrowLeft } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import qrCode from "../../Assets/image/QrCode.png";
import share from "../../Assets/image/share.png";
import { getOrders } from "../../service/api";
import moment from "moment";
import { ROUTES } from "../../constants/routes";
import { NumericFormat } from "react-number-format";
import { getLocale } from "../../constants/language";

function Coin() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  // console.log("%c Line:11 🍇 data", "color:#ea7e5c", data);

  const getData = () => {
    let params = { page: 1, sizePerPage: 100, pagination: true };
    setLoading(true);
    getOrders(params)
      ?.then((res) => {
        if (res?.status) {
          console.log("%c Line:15 🍑 res", "color:#93c0a4", res);
          setData(res?.data?.result);
        }
      })
      .catch((e) => {
        console.log("%c Line:19 🍿 e", "color:#465975", e);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  const Skeleton = () => {
    return (
      <div className="flex justify-between items-center border-b border-[#6a6a6a] cursor-pointer hover:bg-[#f0f0f0] py-4 animate-pulse">
        <div className="text-left space-y-2">
          <div className="w-32 h-4 bg-slate-200 rounded"></div>
          <div className="w-24 h-3 bg-slate-200 rounded"></div>
        </div>
        <div className="text-right space-y-2">
          <div className="w-20 h-4 bg-slate-200 rounded"></div>
          <div className="w-16 h-3 bg-slate-200 rounded"></div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-12 flex flex-col">
        <div className="flex justify-between items-center">
          <IconArrowNarrowLeft
            className="size-7 cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <p></p>
        </div>
        <div className="mt-5">
          <h2 className="text-[22px] font-medium text-[#111111] leading-[145%] tracking-[-1px] text-left">
            {/* History */}
            {getLocale("history")}
          </h2>

          <div className="mt-3">
            {loading
              ? Array.from({ length: 4 }).map((_, index) => (
                  <Skeleton key={index} />
                ))
              : data &&
                data.length > 0 &&
                data.map((obj, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center border border-[#d1d1d1] rounded-lg cursor-pointer hover:bg-[#f9f9f9] shadow-sm p-4 mb-3 transition-all duration-200"
                    onClick={() => navigate(`/orderStatus/${obj?._id}`)}
                  >
                    <div className="flex items-center gap-3">
                      <div
                        className={`p-2 rounded-full ${
                          obj?.type === "BUY" ? "bg-green-200" : "bg-red-200"
                        }`}
                      >
                        {obj?.type === "BUY" ? (
                          <i className="text-green-600 fas fa-arrow-up"></i>
                        ) : (
                          <i className="text-red-600 fas fa-arrow-down"></i>
                        )}
                      </div>

                      <div className="text-left">
                        <h1 className="text-[16px] font-medium leading-[140%] tracking-[0.2px] text-[#111111]">
                          {obj?.type} {obj?.coin?.name}
                        </h1>
                        <p className="text-[12px] leading-[145%] tracking-normal text-[#707070] mt-1">
                          {moment(obj?.createdAt)?.format("DD/MM/YYYY")}
                        </p>
                      </div>
                    </div>

                    <div className="text-right">
                      <h1 className="text-[16px] font-bold leading-[140%] tracking-[0.2px] text-[#111111]">
                        <NumericFormat
                          value={obj?.quantity}
                          displayType="text"
                          decimalScale={4}
                          thousandSeparator
                        />{" "}
                        {obj?.coin?.name}
                      </h1>
                      <p className="text-[14px] leading-[145%] tracking-normal text-[#707070]">
                        ${parseFloat(obj?.amount).toFixed(2)}
                      </p>
                    </div>
                  </div>
                ))}
          </div>
        </div>
        <div className="mt-[100px] hidden">
          <h2 className="text-[22px] font-medium text-[#111111] leading-[145%] tracking-[-1px] text-left">
            {/* Recurring purchase */}
            {getLocale("recurringPurchase")}
          </h2>
          <p className="text-[16px] font-normal text-[#707070] leading-[140%] tracking-[0.2px] text-left">
            {/* Not sure when to buy? Set up a recurring purchase. */}
            {getLocale("recurringPurchaseInfo")}
          </p>
          <button
            onClick={() => navigate(ROUTES.CRYPTO_INFO)}
            className="w-full p-4 bg-white text-[#111111] rounded-lg mt-8 text-base border border-md border-[#CFCFCF]"
          >
            {/* Set up a recurring purchase. */}
            {getLocale("setRecurring")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Coin;
