import React, { useEffect, useState } from "react";
import { getAllP2PSupportedCoins } from "../../../service/api";
import {
  IconArrowNarrowLeft,
  IconSearch,
  IconTrash,
} from "@tabler/icons-react";
import { Danger } from "../../Core/Errors";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/user/actions";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { NumericFormat } from "react-number-format";
import { getLocale } from "../../../constants/language";

function LiteBuy() {
  const [loader, setLoader] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const { P2PLiteorderPayload } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getAssets = () => {
    try {
      setLoader(true);
      getAllP2PSupportedCoins(
        pageNumber,
        true,
        sizePerPage,
        P2PLiteorderPayload?.type === "SELL" ? true : false
      )
        .then((res) => {
          setData(res.data?.result);
          setCount(res.data?.total);
          setLoader(false);
        })
        .catch((error) => {
          Danger(error?.response?.data?.message);
          setLoader(false);
        });
    } catch (error) {
      Danger(error?.response?.data?.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    getAssets();
  }, [P2PLiteorderPayload?.type]);

  return (
    <>
      <div className="h-full min-h-[100vh] relative bg-white font-sans pb-12 pt-4 flex flex-col ">
        <div className="">
          <div className=" px-4 w-full  items-center rounded-md flex justify-between gap-1">
            <IconArrowNarrowLeft
              className="  cursor-pointer "
              onClick={() => {
                dispatch(
                  actions.setP2PLitePaylaod({
                    type: "",
                    offerId: null,
                    totalAmountCoin: null,
                    totalPaymentInFait: null,
                    paymentMethods: null,
                    paymentTimeLimitMinutes: null,
                  })
                );
                navigate(ROUTES.LANDING);
              }}
              size={30}
            />
            <p className=" text-[14px] font-semibold opacity-80 ">
              {getLocale("cry")}
            </p>
            <p></p>
          </div>
          <div className=" px-4 flex justify-between items-center w-full  mt-4">
            <div className=" w-full bg-bgGray items-center rounded-md h-[30px] flex justify-between gap-1 pl-1">
              <IconSearch size={20} />
              <input
                type="text"
                className=" w-full h-full border-none outline-none placeholder:opacity-[0.5] bg-transparent "
                placeholder={getLocale("sCoin")}
              />
            </div>
          </div>
          <div className="hidden px-4 mt-4">
            <div className=" w-full  items-center rounded-md h-[30px] flex justify-between gap-1">
              <p className=" text-[14px] font-semibold opacity-60 ">
                {getLocale("chcrypto")}
              </p>
              <IconTrash size={30} className=" cursor-pointer" />
            </div>
            {
              <div className=" mt-2">
                <p className=" w-full text-left  uppercase">usdt</p>
              </div>
            }
          </div>
          <div className=" mt-6 px-4 text-left text-textPrime opacity-[0.7]">
            <p>
              {/* P2P Supported Coins */}
              {getLocale("tSearch")}
            </p>
          </div>
          <div>
            {data &&
              data?.length > 0 &&
              data?.map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    // setCurrFilter(false);
                    dispatch(
                      actions.setP2PLitePaylaod({
                        ...P2PLiteorderPayload,
                        coin: item,
                        fiatCurrency: item?.fiatPairs?.[0],
                        price: null,
                      })
                    );
                    if (P2PLiteorderPayload?.type === "BUY") {
                      navigate(ROUTES.LITEBUYENTERAMOUNT);
                    } else {
                      navigate(ROUTES.LITESELLENTERAMOUNT);
                    }
                  }}
                  className={`rounded-md px-2 w-[98%] cursor-pointer mt-2 flex items-center justify-center h-[54px] mx-auto active:bg-[#ccc]`}
                >
                  <img
                    className=" w-10 h-10 rounded-full"
                    alt={item?.name}
                    src={item?.coin?.image}
                  />
                  <div className="text-[#111] text-left ml-3 text-base mr-auto">
                    <h6 className=" text-base ">{item?.coin?.name}</h6>
                    <h3 className="text-[10px] opacity-60">
                      {item?.coin?.symbol}
                    </h3>
                  </div>
                  <div>
                    <div className="text-right text-base ml-auto">
                      <NumericFormat
                        value={item?.coin?.price}
                        decimalScale={4}
                        thousandSeparator
                        displayType="text"
                        className=" text-base"
                        prefix="$"
                      />
                    </div>
                    {P2PLiteorderPayload?.type === "SELL" && (
                      <div className="text-right text-sm ml-auto">
                        <NumericFormat
                          value={item?.userFundingAmount}
                          decimalScale={4}
                          thousandSeparator
                          displayType="text"
                          className=" text-xs"
                          suffix={` ${item?.coin?.symbol}`}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default LiteBuy;
