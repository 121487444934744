import {
  IconArrowNarrowLeft,
  IconChevronRight,
  IconHeadphonesFilled,
  IconSettings,
} from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../Assets/image/favicon.png";
import { ROUTES } from "../../../constants/routes";

const Settings = () => {
  const navigate = useNavigate();
  return (
    <div className="h-full min-h-[100vh] max-h-[100vh] overflow-y-auto relative justify-start bg-white font-sans pt-8 pb-3 flex flex-col">
      <div className=" px-4 flex justify-between items-center">
        <IconArrowNarrowLeft
          className="size-7 cursor-pointer"
          onClick={() => navigate(ROUTES.PROFILE)}
        />
        <h1 className="font-semibold leading-[145%] tracking-[-1px] text-[18px] text-[#111111] ">
          Settings
        </h1>
        <p></p>
      </div>

      <div className=" text-sm text-left rounded-lg mt-4 px-4">
        <p className=" text-textPrime opacity-80  ">Payment</p>
      </div>

      {/* Action Grid */}
      <div className="">
        <div className="space-y-1 mt-1">
          {[
            { name: "Payment Currency", icon: "INR" },
            { name: "Payment Method", icon: "" },
          ].map((item, index) => (
            <div
              onClick={() => {
                if (index === 1) {
                  navigate(ROUTES.P2PLITESETTINGS);
                }
              }}
              className="w-full p-4 text-center hover:bg-[#ccc]/[0.5] 
             cursor-pointer text-gray-500 text-sm flex justify-between px-4  "
            >
              <div className=" flex items-center gap-2 ">
                <p>{item.name}</p>
              </div>
              <div className=" flex items-center gap-2">
                <p>{item.icon}</p>
                <IconChevronRight />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Settings;
