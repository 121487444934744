import { IconX } from "@tabler/icons-react";
import React, { useState } from "react";
// import step1 from "../../Assets/image/step1.png";
// import step1Complete from "../../Assets/image/step1Complete.png";
// import step2 from "../../Assets/image/step2.png";
// import step2Complete from "../../Assets/image/step2Complete.png";
// import step3 from "../../Assets/image/step3.png";
// import step3Complete from "../../Assets/image/stepLine.png";

import { useNavigate } from "react-router-dom";
import avatar from "../../Assets/image/user.png";
import driving from "../../Assets/image/driving.png";
import drivingBack from "../../Assets/image/back.png";
import cus from "../../Assets/image/customer.png";
import { Danger, Success } from "../Core/Errors";
import { uploadDcoument } from "../../service/api";
import { ROUTES } from "../../constants/routes";
import { CircularProgress } from "@mui/material";
import actions from "../../redux/user/actions";
import { getLocale } from "../../constants/language";
// import bgImg from "../../Assets/image/sPhotoIdBg.png";
// import { Warning } from "../Core/Errors";
// import { ROUTES } from "../../constants/routes";
// import { getLocale } from "../../constants/language";

function SPhotoId() {
  const navigate = useNavigate();
  const dispatch = useNavigate();
  const [steps, setSteps] = useState(1);
  const [loading, setLoading] = useState(false);
  const [forontSide, setFrontSide] = useState(null);
  const [frontSideUrl, setFrontSideUrl] = useState(null);
  const [BackSide, setBackSide] = useState(null);
  const [BackSideUrl, setBackSideUrl] = useState(null);
  const [profile, setProfile] = useState(null);
  const [profileUrl, setProfileUrl] = useState(null);

  const handleChange = (side, event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    if (side === 1) {
      setFrontSide(file);
      setFrontSideUrl(url);
    } else if (side === 2) {
      setBackSide(file);
      setBackSideUrl(url);
    } else if (side === 3) {
      setProfile(file);
      setProfileUrl(url);
    }
    // setSteps(side + 1);
  };

  const uploadFIles = () => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("documentFrontImage", forontSide);
      formData.append("documentBackImage", BackSide);
      formData.append("userSelfie", profile);
      uploadDcoument(formData)
        .then((resp) => {
          if (resp.status) {
            dispatch(actions.setUser(resp?.data));
            Success("KYC Verification Submitted for Review").then((_) => {
              navigate(ROUTES.LANDING);
            });
          }
        })
        .catch((error) => {
          Danger(error?.response?.data?.message);
          setLoading(false);
        })
        .finally((res) => {
          setLoading(false);
        });
    } catch (error) {}
  };

  const CardKyc = ({ step, type }) => {
    return (
      <div className="bg-[#eae9e9] w-[390px] border border-[#d1d1d1] rounded-lg h-[65vh] mx-auto p-3 flex flex-col gap-y-2 justify-center items-center">
        {step === 3 ? (
          <img src={cus} className="w-[auto] mx-auto rounded-lg h-[26vh]" />
        ) : (
          <img src={step===2?drivingBack:driving} className="w-[auto] mx-auto rounded-lg h-[26vh]" />
        )}
        {/* {step === 2 && (
          <div className="w-[80%] rounded-lg overflow-hidden border border-[#0f0f0f] min-h-[15vh]">
            <div className="h-8 bg-[#efa018ca]"></div>
            <div className="flex items-center justify-center gap-2 p-3">
              <div className="w-3/4">
                <div className="w-4/6 bg-[#d6d6d6] h-3 my-1"></div>
                <div className="w-5/6 bg-[#d6d6d6] h-3 my-1"></div>
                <div className="w-3/6 bg-[#d6d6d6] h-3 my-1"></div>
                <div className="w-4/6 bg-[#d6d6d6] h-3 my-1"></div>
              </div>
            </div>
          </div>
        )}
        {step === 3 && (
          <div className="bg-white relative rounded-full w-1/4  min-h-[12vh]">
            <img
              src={avatar}
              alt=""
              className="size-[100px] absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 bottom-0"
            />
          </div>
        )} */}
        <label className="text-black font-[600] text-lg text-center my-2">
          {step === 1
            ? getLocale("front")
            : step === 2
            ? getLocale("back")
            :getLocale("profile")}
        </label>
        <label className="text-black font-[500] text-sm opacity-70 text-center">
          {getLocale("step")} {step} {getLocale("of")} 3
        </label>
        <label className="text-black font-[500] text-base text-center">
          {step === 1
            ? getLocale("frontint")
            : step === 2
            ? getLocale("backint")
            : getLocale("profileint")}
        </label>
        <label className="text-black font-[500] text-base text-center">
          {getLocale("format")}
        </label>
        <div className="w-full float-right ml-auto mt-5">
          <label
            htmlFor="fileUpload"
            className="p-2 bg-bgBlue text-white rounded-full w-full block text-base font-[700] cursor-pointer"
          >
            {getLocale("choose")}
          </label>
          <input
            id="fileUpload"
            type="file"
            accept="image/*"
            onChange={(e) => handleChange(step, e)}
            className="hidden"
          />
        </div>
      </div>
    );
  };
  const CardKycView = ({ image, step }) => {
    return (
      <div className="bg-[#eae9e9] w-[390px] border border-[#d1d1d1] rounded-lg h-[60vh] mx-auto p-3 flex flex-col gap-y-2 justify-center items-center">
        <img
          src={image}
          className={`size-48 ${
            step === 3 ? "rounded-full" : "rounded-lg"
          } mx-auto border border-black`}
        />
        <ul className="mt-4 mb-2 text-[#7b7a7a] text-sm text-left list-disc list-inside space-y-2">
          <li>{getLocale("pinst1")}</li>
          <li>{getLocale("pinst2")}</li>
          <li>{getLocale("pinst3")} </li>
        </ul>
        <div className="flex gap-2 w-full justify-center items-center">
         
          <div className="w-full float-right ml-auto mt-5">
            <label
              htmlFor="fileUpload"
              className="p-2 bg-red-300 hover:bg-red-400 transition-colors text-red-600 rounded-full w-full block text-base font-[700] cursor-pointer border border-red-600"
            >
              {getLocale("try")}
            </label>
            <input
              id="fileUpload"
              type="file"
              accept="image/*"
              disabled={loading}
              onChange={(e) => {
                if (step === 1) {
                  setFrontSideUrl(null);
                  setFrontSide(null);
                } else if (step === 2) {
                  setBackSide(null);
                  setBackSideUrl(null);
                } else if (step === 3) {
                  setProfileUrl(null);
                  setProfile(null);
                }
                handleChange(step, e);
              }}
              className="hidden"
            />
          </div>
          {loading ? (
            <button
              disabled={loading}
              className="w-full p-2 bg-green-300 text-green-600 rounded-full mt-5 text-base border border-bgBlue"
            >
              <CircularProgress size={19} color="white" thickness={6} />
            </button>
          ) : (
            <button
              onClick={() => {
                if (step === 3) {
                  uploadFIles();
                } else {
                  setSteps(step + 1);
                }
              }}
              disabled={loading}
              className="w-full p-2 bg-bgBlue hover:bg-opacity-85 text-white rounded-full mt-5 text-base font-[700] border border-bgBlue"
            >
              {step === 3 ? getLocale("cmp") : getLocale("cnf")}
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="h-full min-h-[100vh] relative bg-white font-sans px-4 py-4 flex flex-col justify-start">
        <div className=" flex justify-between items-center">
          <h2 className=" text-[20px] font-[700] text-left ">
            {getLocale("doc")}
          </h2>
          <IconX
            className="size-6 cursor-pointer transition-transform duration-300 hover:rotate-90"
            onClick={() => navigate(-1)}
          />
        </div>
        <div className="flex items-center justify-center gap-2 mt-4 my-2">
          <div
            onClick={() => setSteps(1)}
            className={`rounded-md h-1 w-12 cursor-pointer ${
              steps >= 1 ? "bg-[#373641]" : "bg-[#f3f3f3]"
            }`}
          ></div>
          <div
            onClick={() => setSteps(2)}
            className={`rounded-md h-1 w-12 cursor-pointer ${
              steps >= 2 ? "bg-[#373641]" : "bg-[#f3f3f3]"
            }`}
          ></div>
          <div
            onClick={() => setSteps(3)}
            className={`rounded-md h-1 w-12 cursor-pointer ${
              steps >= 3 ? "bg-[#373641]" : "bg-[#f3f3f3]"
            }`}
          ></div>
        </div>
        <div className="mt-4">
          {steps === 1 ? (
            !frontSideUrl ? (
              <CardKyc step={1} type="front" />
            ) : (
              <CardKycView image={frontSideUrl} step={1} />
            )
          ) : steps === 2 ? (
            !BackSideUrl ? (
              <CardKyc step={2} type="back" />
            ) : (
              <CardKycView image={BackSideUrl} step={2} />
            )
          ) : !profileUrl ? (
            <CardKyc step={3} type="profile" />
          ) : (
            <CardKycView image={profileUrl} step={3} />
          )}
        </div>
      </div>
    </div>
  );
}

export default SPhotoId;

// OLD CODE
// {/* <div className="h-full min-h-[100vh] relative bg-black font-sans pt-12 flex flex-col ">
// {steps === 0 ? (
//   <div>
//     <div className=" z-10 relative">
//       <div className="px-4 ">
//         <div className=" flex justify-between items-center">
//           <IconChevronLeft
//             color="white"
//             className="size-7 cursor-pointer"
//             onClick={() => navigate(-1)}
//           />
//           {frontSideUrl ? (
//             <img src={step1Complete} alt="step-top" />
//           ) : (
//             <img src={step1} alt="step-top" />
//           )}
//           <p></p>
//         </div>
//       </div>
//       <div className=" w-full mt-1 relative h-full ">
//         <div>
//           {frontSideUrl ? (
//             <img
//               className=" min-h-[50vh] z-0 w-full h-full"
//               src={frontSideUrl}
//               alt=""
//             />
//           ) : (
//             <div className=" h-full w-full min-h-[50vh] items-center flex justify-center bg-white ">
//               <div className=" w-[60%]">
//                 <div className=" w-full">
//                   <label
//                     htmlFor="fileInput"
//                     className=" flex  justify-between px-4 w-full py-2 bg-[#183056] text-white font-semibold rounded cursor-pointer hover:bg-[#183056] "
//                   >
//                     <IconBookUpload />
//                     {/* Select a Front-side image */}
//                     {getLocale("frontPhoto")}
//                   </label>
//                   <input
//                     id="fileInput"
//                     type="file"
//                     className="hidden w-full"
//                     onChange={(e) => handleChange(1, e)}
//                   />
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//         <div className=" relative -m-2 z-40 rounded-tl-[30px] rounded-tr-[30px] bg-white px-4 py-8">
//           <h1 className="text-left font-semibold text-[22px]">
//             {/* Is the photo clear and legible? */}
//             {getLocale("clearPhoto")}
//           </h1>
//           <p className="text-[#707070] text-[16px] space-x-[0.02px] text-left">
//             {/* Please make sure that the text is legible and that your
//             entire card is visible */}
//             {getLocale("photoInfo")}
//           </p>
//           <button
//             onClick={() => {
//               if (frontSideUrl) {
//                 setSteps(1);
//               } else {
//                 Warning(
//                   "Please first upload Front side of the document"
//                 );
//               }
//             }}
//             className="w-full p-4 bg-bgBlue text-white rounded-lg mt-8 text-base"
//           >
//             {/* Yes, it looks fine */}
//             {getLocale("yes")}
//           </button>
//           <button className="w-full p-4 bg-white text-[#111111] rounded-lg text-base mt-5 border border-[#CFCFCF] ">
//             {/* Take again */}
//             {getLocale("takeAgain")}
//           </button>
//         </div>
//       </div>
//     </div>
//   </div>
// ) : steps === 1 ? (
//   <div>
//     <div className=" z-10 relative">
//       <div className="px-4 ">
//         <div className=" flex justify-between items-center">
//           <IconChevronLeft
//             color="white"
//             className="size-7 cursor-pointer"
//             onClick={() => setSteps(0)}
//           />
//           {BackSideUrl ? (
//             <img src={step2Complete} alt="step-top" />
//           ) : (
//             <img src={step2} alt="step-top" />
//           )}
//           <p></p>
//         </div>
//       </div>
//       <div className=" w-full mt-1 relative h-full ">
//         <div>
//           {BackSideUrl ? (
//             <img
//               className=" z-0 min-h-[50vh] w-full h-full"
//               src={BackSideUrl}
//               alt=""
//             />
//           ) : (
//             <div className=" h-full w-full min-h-[50vh] items-center flex justify-center bg-white ">
//               <div className=" w-[60%]">
//                 <div className=" w-full">
//                   <label
//                     htmlFor="fileInput"
//                     className=" flex  justify-between px-4 w-full py-2 bg-[#183056] text-white font-semibold rounded cursor-pointer hover:bg-[#183056] "
//                   >
//                     <IconBookUpload />
//                     {/* Select a Back-side image */}
//                     {getLocale("backPhoto")}
//                   </label>
//                   <input
//                     id="fileInput"
//                     type="file"
//                     className="hidden w-full"
//                     onChange={(e) => handleChange(2, e)}
//                   />
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//         <div className=" relative -m-2 z-40 rounded-tl-[30px] rounded-tr-[30px] bg-white px-4 py-8">
//           <h1 className="text-left font-semibold text-[22px]">
//             {/* Is the photo clear and legible? */}
//             {getLocale("clearPhoto")}
//           </h1>
//           <p className="text-[#707070] text-[16px] space-x-[0.02px] text-left">
//             {/* Please make sure that the text is legible and that your
//             entire card is visible */}
//             {getLocale("photoInfo")}
//           </p>
//           <button
//             onClick={() => {
//               if (BackSideUrl) {
//                 setSteps(2);
//               } else {
//                 Warning(
//                   "Please first upload back side of the document"
//                 );
//               }
//             }}
//             className="w-full p-4 bg-bgBlue text-white rounded-lg mt-8 text-base"
//           >
//             {/* Yes, it looks fine */}
//             {getLocale("yes")}
//           </button>
//           <button className="w-full p-4 bg-white text-[#111111] rounded-lg text-base mt-5 border border-[#CFCFCF] ">
//             {/* Take again */}
//             {getLocale("takeAgain")}
//           </button>
//         </div>
//       </div>
//     </div>
//   </div>
// ) : (
//   <div>
//     <div className=" z-10 relative">
//       <div className="px-4 ">
//         <div className=" flex justify-between items-center">
//           <IconChevronLeft
//             color="white"
//             className="size-7 cursor-pointer"
//             onClick={() => setSteps(1)}
//           />
//           {profileUrl ? (
//             <img src={step3Complete} alt="step-top" />
//           ) : (
//             <img src={step3} alt="step-top" />
//           )}
//           <p></p>
//         </div>
//       </div>
//       <div className=" w-full mt-1 relative h-full ">
//         <div>
//           {profileUrl ? (
//             <img
//               className=" z-0 w-full h-full"
//               src={profileUrl}
//               alt=""
//             />
//           ) : (
//             <div className=" h-full w-full min-h-[50vh] items-center flex justify-center bg-white ">
//               <div className=" w-[40%]">
//                 <div className=" w-full">
//                   <label
//                     htmlFor="fileInput"
//                     className=" flex  justify-between px-4 w-full py-2 bg-[#183056] text-white font-semibold rounded cursor-pointer hover:bg-[#183056] "
//                   >
//                     <IconBookUpload />
//                     Select a File
//                   </label>
//                   <input
//                     id="fileInput"
//                     type="file"
//                     className="hidden w-full"
//                     onChange={(e) => handleChange(3, e)}
//                   />
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//         <div className=" relative -m-2 z-40 rounded-tl-[30px] rounded-tr-[30px] bg-white px-4 py-8">
//           <h1 className="text-left font-semibold text-[22px]">
//             {/* Is the photo clear and legible? */}
//             {getLocale("clearPhoto")}
//           </h1>
//           <p className="text-[#707070] text-[16px] space-x-[0.02px] text-left">
//             {/* Please make sure that the text is legible and that your
//             entire card is visible */}
//             {getLocale("photoInfo")}
//           </p>
//           <button
//             onClick={() => {
//               if (profileUrl) {
//                 navigate(ROUTES.FACE_ID, {
//                   state: {
//                     front: forontSide,
//                     back: BackSide,
//                     profile: profile,
//                   },
//                 });
//               } else {
//                 Warning("Please first upload selfie");
//               }
//             }}
//             className="w-full p-4 bg-bgBlue text-white rounded-lg mt-8 text-base"
//           >
//             {/* Yes, it looks fine */}
//             {getLocale("yes")}
//           </button>
//           <button className="w-full p-4 bg-white text-[#111111] rounded-lg text-base mt-5 border border-[#CFCFCF] ">
//             {/* Take again */}
//             {getLocale("takeAgain")}
//           </button>
//         </div>
//       </div>
//     </div>
//   </div>
// )}
// </div> */}
