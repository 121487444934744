import {
  IconArrowNarrowLeft,
  IconCheck,
  IconChevronRight,
} from "@tabler/icons-react";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import actions from "../../../redux/user/actions";
import { ROUTES } from "../../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
const List = [
  { name: "P2P Payment Method(s)", icon: "Added" },
  { name: "P2P Notifications", icon: "" },
];
const P2pSettings = () => {
  const navigate = useNavigate();
  const { mode } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const [open, setOPen] = useState(false);
  const handleModeSwitch = () => {
    dispatch(
      actions.switchMode(mode === "Advertisement" ? "Order" : "Advertisement")
    );
    setOPen(true);
  };
  return (
    <div className="h-full min-h-[100vh] max-h-[100vh] overflow-y-auto relative justify-start bg-white font-sans pt-8 pb-3 flex flex-col">
      <div className=" px-4 flex justify-between items-center">
        <IconArrowNarrowLeft
          className="size-7 cursor-pointer"
          onClick={() => navigate(ROUTES.PROFILE)}
        />
        <h1 className="font-semibold leading-[145%] tracking-[-1px] text-[18px] text-[#111111] ">
          P2P Settings
        </h1>
        <p></p>
      </div>

      <div className=" text-sm text-left rounded-lg mt-4 px-4">
        <p className=" text-textPrime opacity-80 text-[16px] ">
          Payment Methods
        </p>
      </div>
      <div className=" text-sm text-left rounded-lg mt-4 px-4">
        <p className=" text-textPrime text-[16px] ">Payment Settings</p>
        <label className="  text-[10px] text-textPrime opacity-80 ">
          You can Buy and Sell crypto through our Peer-to-Peer exchange
        </label>
      </div>

      {/* Action Grid */}
      <div className="">
        <div className="space-y-1 mt-1">
          {List.map((item, index) => (
            <div
              onClick={() => {
                if (index === 0) {
                  navigate(ROUTES.PAYMENTMETHODS);
                } else if (index === 1) {
                  navigate(ROUTES.P2PLITENOTIFICATIONSETTINGS);
                }
              }}
              className="w-full p-4 text-center hover:bg-[#ccc]/[0.5] 
               cursor-pointer text-gray-500 text-sm flex justify-between px-4  "
            >
              <div className=" flex items-center gap-2 ">
                <p>{item.name}</p>
              </div>
              <div className=" flex items-center gap-2">
                <p>{item.icon}</p>
                <IconChevronRight />
              </div>
            </div>
          ))}
          <div
            onClick={handleModeSwitch}
            className="w-full p-4 text-center hover:bg-[#ccc]/[0.5] 
               cursor-pointer text-gray-500 text-sm flex justify-between px-4  "
          >
            <div className=" flex items-center gap-2 ">
              <p>{"Switch Mode"}</p>
            </div>
            <div className=" flex items-center gap-2">
              <p>{mode}</p>
              <IconChevronRight />
            </div>
          </div>
        </div>
      </div>
      {open && (
        <PopUp
          setOpen={setOPen}
          switchedTO={mode === "Advertisement" ? "Advertisement" : "Order"}
          desc={
            mode === "Advertisement"
              ? "Advertisement mode is optimized for users who post Advertisements"
              : "Advertisement mode is optimized for users who post Advertisements"
          }
        />
      )}
    </div>
  );
};

export default P2pSettings;

const PopUp = ({ switchedTO, desc, btnText, setOpen }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setOpen(false);
      }}
      className={` w-full h-full absolute z-50 bg-bgBlue/[0.4] top-0 left-0 px-4 flex items-center justify-center `}
    >
      <div className=" bg-white min-h-[300px]  w-full rounded-md shadow-md  flex flex-col items-center py-4 px-4 ">
        <div className=" size-10 rounded-full bg-green-500 flex items-center justify-center">
          <IconCheck className=" font-semibold" />
        </div>
        <p className=" mt-4 text-textPrime text-[22px] font-semibold ">
          Switched to {switchedTO} Mode
        </p>
        <p className=" mt-4 text-textPrime text-[16px] font-normal ">{desc}</p>
        <div
          onClick={() => {
            navigate(ROUTES.P2PHOME);
            setOpen(false);
          }}
          className=" font-semibold cursor-pointer mt-4 flex items-center justify-center h-[50px] w-full rounded-md transition-all  hover:scale-[1.02] bg-bgBlue text-textWhite text-[18px] "
        >
          {" "}
          Go to P2P{" "}
        </div>
        <button className="font-semibold mt-4 h-[50px] w-full rounded-md transition-all  hover:scale-[1.02]  bg-bgGray text-textPrimetext-[18px] ">
          {" "}
          Close{" "}
        </button>
      </div>
    </div>
  );
};
