import {
  IconArrowNarrowLeft,
  IconDeviceIpadHorizontalPlus,
  IconArrowsExchange2,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { getLocale } from "../../../constants/language";
import { useDispatch, useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";
import actions from "../../../redux/user/actions";
import Currencies from "./Currencies";
import { Danger } from "../../Core/Errors";

function SellAmount() {
  const navigate = useNavigate();
  const { P2PLiteorderPayload } = useSelector((state) => state.user);
  console.log(
    "%c Line:18 🍅 P2PLiteorderPayload",
    "color:#4fff4B",
    P2PLiteorderPayload
  );
  const [byCrypto, setByCrypto] = useState(true);
  const [open, setOpen] = useState(false);
  const [priceErr, setPriceErr] = useState(false);
  console.log("%c Line:21 🥖 priceErr", "color:#3f7cff", priceErr);
  const dispatch = useDispatch();
  const coin = useLocation().state?.coin;

  useEffect(() => {
    dispatch(
      actions.setP2PLitePaylaod({
        ...P2PLiteorderPayload,
        byWhich: byCrypto,
      })
    );
  }, [byCrypto]);

  const handleClick = (value, index) => {
    if (index === 11) {
      let copyNumber = P2PLiteorderPayload?.price;

      copyNumber = copyNumber.substring(
        0,
        P2PLiteorderPayload?.price.length - 1
      );
      dispatch(
        actions.setP2PLitePaylaod({
          ...P2PLiteorderPayload,
          price: copyNumber,
        })
      );
    } else {
      const val = P2PLiteorderPayload.price ? P2PLiteorderPayload.price : "";

      dispatch(
        actions.setP2PLitePaylaod({
          ...P2PLiteorderPayload,
          price: val + value,
        })
      );
    }
  };
  const handleGoNext = () => {
    if (
      (byCrypto
        ? +P2PLiteorderPayload?.coin?.userFundingAmount *
          +P2PLiteorderPayload?.coin?.coin?.price
        : P2PLiteorderPayload?.coin?.userFundingAmount) <=
      P2PLiteorderPayload?.price
    ) {
      setPriceErr(getLocale("noBalance"));
      return;
    }
    if (
      P2PLiteorderPayload?.fiatCurrency?.maxP2PAmount >=
        P2PLiteorderPayload?.price &&
      P2PLiteorderPayload?.price >=
        P2PLiteorderPayload?.fiatCurrency?.minP2PAmount
    ) {
      navigate(ROUTES.SELECTPAYMENTFORLITE);
    } else {
      Danger(getLocale("amRange"));
    }
  };

  useEffect(() => {
    if (
      byCrypto
        ? P2PLiteorderPayload?.fiatCurrency?.maxP2PAmount >=
            P2PLiteorderPayload?.price &&
          P2PLiteorderPayload?.price >=
            P2PLiteorderPayload?.fiatCurrency?.minP2PAmount
        : P2PLiteorderPayload?.price >=
            P2PLiteorderPayload?.fiatCurrency?.minP2PAmount /
              Number(P2PLiteorderPayload?.coin?.coin?.price ?? 1) &&
          P2PLiteorderPayload?.fiatCurrency?.maxP2PAmount /
            Number(P2PLiteorderPayload?.coin?.coin?.price ?? 1) >
            P2PLiteorderPayload?.price
    ) {
      setPriceErr(false);
    } else {
      setPriceErr(!P2PLiteorderPayload?.price ? false : true);
    }
  }, [P2PLiteorderPayload?.price]);

  return (
    <div>
      <div className="h-full min-h-[100vh] relative justify-between bg-white font-sans px-4 pt-8 pb-3 flex flex-col">
        <div>
          <div className=" flex justify-between items-center">
            <IconArrowNarrowLeft
              className="size-7 cursor-pointer"
              onClick={() => navigate(ROUTES.LITEBUY)}
            />
            <h1 className="font-semibold leading-[145%] tracking-[-1px] text-[18px] text-[#111111] ">
              {/* Buy  */}
              {getLocale("sell")} {P2PLiteorderPayload?.coin?.coin?.symbol}
              <span>{coin?.symbol}</span>{" "}
            </h1>
            <p
              onClick={() => setOpen(true)}
              className=" size-10 rounded-full hover:bg-[#ccc] transition-all active:bg-bg-[#ccc] flex items-center justify-center "
            >
              <IconDeviceIpadHorizontalPlus
                size={25}
                className=" cursor-pointer "
              />
            </p>
          </div>
          <div className="mt-5 ">
            <div className=" flex justify-between w-full gap-1 mt-3">
              <div className="mb-4 w-full">
                <div className=" flex justify-between items-center ">
                  <label className="block text-sm font-[500] mb-1 text-left">
                    {getLocale("payi")}{" "}
                    {priceErr && (
                      <span className="text-red-500">{priceErr}</span>
                    )}
                  </label>
                  {/* <div
                    onClick={() => {
                      setByCrypto(!byCrypto);
                    }}
                    className=" flex items-center text-sm font-normal gap-1 cursor-pointer"
                  >
                    Buy {byCrypto ? "crypto" : "Money"}{" "}
                    <IconArrowsExchange2 className=" mt-1 " size={20} />
                  </div> */}
                  <label className="flex items-center cursor-pointer gap-2 ">
                    <span className="ms-3 -mt-1 text-sm font-medium text-gray-900 dark:text-gray-500">
                      {getLocale("byCrypto")}
                    </span>
                    <input
                      type="checkbox"
                      checked={byCrypto}
                      onChange={() => {
                        setByCrypto(!byCrypto);
                      }}
                      className="sr-only peer"
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
                    <span className="ms-1 -mt-1 text-sm font-medium text-gray-900 dark:text-gray-500">
                      {getLocale("byFiat")}
                    </span>
                  </label>
                </div>
                <div
                  className={` w-full  border  ${
                    priceErr ? "border-red-400" : "border-gray-300"
                  } 
                         rounded-lg p-2 px-4  h-[45px] flex  mt-2 justify-between items-center `}
                >
                  <input
                    value={P2PLiteorderPayload?.price}
                    onChange={(e) => {
                      dispatch(
                        actions.setP2PLitePaylaod({
                          ...P2PLiteorderPayload,
                          price: e.target.value,
                        })
                      );
                      setPriceErr(false);
                    }}
                    className={`w-full h-full  outline-none border-none text-left `}
                    placeholder="Enter total amount"
                    type="number"
                    name=""
                    id=""
                  />
                  {!byCrypto ? (
                    <div className=" flex items-center gap-1">
                      <img
                        src={P2PLiteorderPayload?.coin?.coin?.image}
                        className=" size-6 rounded-full "
                      />
                      <label className="block text-sm font-semibold text-left uppercase">
                        {P2PLiteorderPayload?.coin?.coin?.symbol}
                      </label>
                    </div>
                  ) : (
                    <div className=" flex items-center gap-1">
                      <img
                        src={P2PLiteorderPayload?.fiatCurrency?.image}
                        className=" size-6 rounded-full "
                      />
                      <label className="block text-sm font-semibold text-left uppercase">
                        {P2PLiteorderPayload?.fiatCurrency?.fiatName}
                      </label>
                    </div>
                  )}
                </div>
                <p className=" text-left opacity-40 text-[10px] mt-1 ">
                  {getLocale("aBalance")}{" "}
                  <NumericFormat
                    value={
                      byCrypto
                        ? +P2PLiteorderPayload?.coin?.userFundingAmount *
                          +P2PLiteorderPayload?.coin?.coin?.price
                        : Number(
                            P2PLiteorderPayload?.coin?.userFundingAmount || 0
                          )
                    }
                    decimalScale={4}
                    suffix={
                      byCrypto
                        ? P2PLiteorderPayload?.fiatCurrency?.fiatName
                        : P2PLiteorderPayload?.coin?.coin?.symbol
                    }
                    displayType="text"
                  />
                </p>
                <div>
                  <p className=" text-left opacity-60 text-[10px] mt-1 ">
                    {getLocale("limit")}:{" "}
                    <span className=" text-left ">
                      <NumericFormat
                        decimalScale={2}
                        value={
                          byCrypto
                            ? P2PLiteorderPayload?.fiatCurrency?.minP2PAmount
                            : P2PLiteorderPayload?.fiatCurrency?.minP2PAmount /
                              Number(
                                P2PLiteorderPayload?.coin?.coin?.price ?? 1
                              )
                        }
                        displayType="text"
                        thousandSeparator
                      />{" "}
                      -{" "}
                      <NumericFormat
                        decimalScale={2}
                        value={
                          byCrypto
                            ? P2PLiteorderPayload?.fiatCurrency?.maxP2PAmount
                            : P2PLiteorderPayload?.fiatCurrency?.maxP2PAmount /
                              Number(
                                P2PLiteorderPayload?.coin?.coin?.price ?? 1
                              )
                        }
                        displayType="text"
                        thousandSeparator
                      />{" "}
                      {`${
                        !byCrypto
                          ? P2PLiteorderPayload?.coin?.coin?.symbol
                          : P2PLiteorderPayload?.fiatCurrency?.fiatName
                      } `}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className=" mt-3">
            <div className="bg-white w-full ">
              <div className="grid grid-cols-3 gap-2">
                {[
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
                  ".",
                  "0",
                  <IconArrowNarrowLeft />,
                ].map((key, index) => (
                  <button
                    key={key}
                    onClick={() => handleClick(key, index)}
                    className=" text-black font-medium text-[28px] py-4 px-6 rounded-lg flex justify-center items-center active:bg-[#ccc] "
                  >
                    {key}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div>
            <button
              onClick={handleGoNext}
              className="w-full p-4 bg-bgBlue text-white rounded-lg mt-2 text-base"
            >
              {getLocale("selectMethod")}
            </button>
          </div>
        </div>
      </div>
      <PopUp open={open}>
        <Currencies setOPen={setOpen} />
      </PopUp>
    </div>
  );
}

export default SellAmount;

function PopUp({ children, open }) {
  return (
    <div
      className={` z-30 h-[100%] ${
        open ? " translate-x-0" : " translate-x-[100%]"
      } transition-all rounded-tl-xl rounded-tr-xl border-t bottom-0 overflow-hidden absolute w-full bg-bgWhite right-0 shadow-2xl `}
    >
      {children}
    </div>
  );
}
